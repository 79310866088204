import styled from 'styled-components';

export const Container = styled.div`
  background-color: #6F8080;
  padding: 30px 0;
 
  h2 {
    text-align: start;
    color: #fff;
    width: 1366px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    padding: 0 2rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 2.25rem;
    font-weight: 700;
  }

  .content-box-new {
    width: 1366px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 2rem;
    
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 30px;
    align-items: stretch;

    .box {
      position: relative;
      
      width: 100%;
      max-width: 100%;
      height: 385px;      
      background-color: #ccc;   
      border-radius: 8px;   

      figure {
        width: 100%;
        height: 100%;
        margin: 0;

        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 8px;

        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .texto {
        width: 100%;
        max-width: 100%;
        padding: 1rem;

        position: absolute;
        left: 0;
        bottom: 0;

        h3 {
          font-family: 'Open Sans', sans-serif;
          font-size: 1.125rem;
          font-weight: 600;
          color: #fff;
          margin: 0;
          margin-bottom: 1rem;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        .box-resume-new {
          display: flex;
          align-items: center;
          justify-content: space-between;

          a {
            font-family: 'Open Sans', sans-serif;
            font-size: 0.8125rem;
            font-weight: 600;
            color: var(--orange);
            text-transform: uppercase;
          }

          .social-media-share {
            display: flex;
            align-items: center;
            color: #fff;
            column-gap: 10px;
          }
        }        
      }
    }

    .box-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;

      figure {    
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 100%;
        height: 385px;
        
        background-color: var(--orange);
        border-radius: 8px;

        .image {
          width: 100%;
          height: 220px;
          max-width: 100%;
          overflow: hidden;
          border-radius: 8px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        figcaption {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 1rem;

          h3 {
            font-family: 'Open Sans', sans-serif;
            font-size: 1.125rem;
            font-weight: 600;
            color: #000;
            margin: 0;
            margin-bottom: 1rem;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }

          .box-resume-new {
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
              font-family: 'Open Sans', sans-serif;
              font-size: 0.8125rem;
              font-weight: 600;
              color: #fff;
              text-transform: uppercase;
            }

            .social-media-share {
              display: flex;
              align-items: center;
              color: #fff;
              column-gap: 10px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 990px) {


    .content-box-new {
      grid-template-columns: 1fr;
      row-gap: 20px;

      .box {
        height: 195px;
      }

      .box-group {
        column-gap: 10px;
      }
    }
  }

  @media screen and (max-width: 500px) {

    .content-box-new {
      .box-group {
        figure {
          height: 265px;
        }
      }
      .social-media-share {
        display: none;
      }
    }
  }
`
