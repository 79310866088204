import React from 'react'
import Routes from "./routers/Routes"
import Layout from "./layout"
import "./styles/GlobalStyles"
import GlobalStyles from './styles/GlobalStyles'
import { LanguageProvider } from './hooks/useLanguage'
import { SideBarProvider } from './hooks/useSidebar'

export default function App() {
  return (
    <LanguageProvider>
      <SideBarProvider>
        <GlobalStyles />
        <Layout>
          <Routes />
        </Layout>
      </SideBarProvider>
    </LanguageProvider>
  )
}