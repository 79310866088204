import React from "react";
// import { Link } from 'react-router-dom'

import BannerForm from "../../components/BannerForm";
import SlideShow from "../../components/Slides";
import Player from "../../components/Player";
import GridSponsors from "../../components/GridSponsors";
import BannerParallax from "../../components/BannerParallax";
import EventInfo from "../../components/EventInfo";
import { useLanguage } from "../../hooks/useLanguage";
import { Helmet } from "react-helmet";
import { FeaturedExpositors } from '../../components/FeaturedExpositors';
import { FeaturedCity } from "../../components/FeaturedCity";

const Home = () => {
  const { language } = useLanguage();

  return (
    <>
      <Helmet>
        <title>Rio Pipeline</title>
        <link rel="canonical" href="/" />
      </Helmet>
      <h1 style={{ position: "absolute", display: "none" }}>
        Rio Pipeline - conference {"&"} exhibition
      </h1>
      <SlideShow />
      <EventInfo id="next-section" papers={false}/>
      <Player />
      <BannerParallax>
        <h2 className="content-text">
          {language === "en_US" ? "Conference" : "Congresso"}
        </h2>
        <p className="content-text">
          {language === "en_US"
            ? <span>Rio Pipeline will bring the debate on the most relevant topics for the sector with the participation of renowned professionals. Call for Papers calendar will comming soon, so stay tuned!</span>
            : <span>A Rio Pipeline trará o debate de temas relevantes para o setor com a participação de profissionais renomados. <strong>Em breve divulgaremos o calendário de Call fo Papers</strong>, fique ligado!</span>}
        </p>
        <a href={language === 'en_US' ? "/schedule" : "/programacao"}>
          <button className='btn--outline'>
            {language === 'en_US'
              ? 'Check out the 2023 agenda'
              : 'Confira da programação de 2023'}
          </button>
        </a>
      </BannerParallax>
      <FeaturedExpositors />
      {/* @todo: só vai estar disponível em 2025 */}
      {/* <FeaturedCity /> */}
      { <GridSponsors />}
      <BannerForm />
      {/* <BannerNew /> */}
    </>
  );
};

export default Home;
