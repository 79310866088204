import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import Breadcump from '../../components/Breadcump'
import CardNew from '../../components/CardNew'
import { useLanguage } from '../../hooks/useLanguage'
import { GET_CATEGORIES } from '../../queries/pages'
import { client } from '../../Services/wordpress'
import { Helmet } from "react-helmet";

import { Container } from '../../styles/PageCategory'

function Category() {
  const { language } = useLanguage()
  let history = useHistory();

  const { slug } = useParams()
  const [data, setData] = useState([])

  const per_page = 10
  const [after, setAfter] = useState('')
  const [hasMorePost, setMorePost] = useState(true)
  const [loadingMorePost, setLoadingMorePost] = useState(false)
  const [firstLoading, setFirstLoading] = useState(true)

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  useEffect(() => {
    if (firstLoading === false) {
      if (language === 'pt_BR') {
        history.push(`/noticias`)
      } else {
        history.push(`/news`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  async function getData() {
    const res = await client.query({
      query: GET_CATEGORIES,
      variables: { slug, per_page, after }
    })

    const { data } = res

    if (data) {
      const newData = data.categories.nodes[0]?.posts.nodes.map(post => {
        return {
          id: post.id,
          title: post.title,
          slug: post.slug,
          image: {
            url: post.featuredImage?.node.mediaItemUrl,
            alt: post.featuredImage?.node.altText,
          },
          categories: post.categories.nodes.map(category => {
            return {
              id: category.id,
              title: category.name,
              slug: category.slug,
            }
          }),
        }
      })

      setData(old => {
        if (after) {
          return [...old, ...newData]
        }

        return newData || []
      })

      setMorePost(data.categories.nodes[0]?.posts.pageInfo.hasNextPage)
      setAfter(data.categories.nodes[0]?.posts.pageInfo.endCursor)
      setLoadingMorePost(false)
      setFirstLoading(false)
    }
  }

  function handleMorePost() {
    if (hasMorePost) {
      setLoadingMorePost(true)
      getData()
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? 'Category - Rio Pipeline'
            : 'Categoria - Rio Pipeline'
          }
        </title>

        {language === 'en_US'
          ? <link rel="canonical" href="/category" />
          : <link rel="canonical" href="/categoria" />
        }
      </Helmet>
      <h1 style={{ position: 'absolute', display: 'none' }}>Rio Pipeline - conference {'&'} exhibition</h1>
      <Breadcump />
      <Container>
        <h2>{language === "en_US" ? "Category" : "Categoria"}</h2>
      </Container>
      <CardNew
        posts={data}
        onclick={handleMorePost}
        loading={loadingMorePost}
        hasMorePost={hasMorePost}
        firstLoad={firstLoading}
      />
    </Fragment>
  )
}

export default Category
