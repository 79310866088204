import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../hooks/useLanguage';
import SocialShare from '../SocialShare';
import { Container, CartContainer } from './styles'

function CardNew({ posts, onclick, loading, hasMorePost, title, firstLoad }) {
  const { language } = useLanguage()

  if (firstLoad) {
    return (
      <Container>
        <p className="text-message">
          {language === 'en_US'
            ? 'Loading...'
            : 'Carregando...'}
        </p>
      </Container>
    )
  }

  return (
    <Fragment>
      <Container>
        <h2>{title}</h2>

        {posts.length === 0 && firstLoad === false && (
          <p className="text-message">
            {language === 'en_US'
              ? 'No posts found..'
              : 'Nenhum post encontrado.'}
          </p>
        )}
      </Container>
      <CartContainer>
        {posts?.map((post) => (
          <div key={post.id} className='post'>
            <Link to={language === 'pt_BR'
              ? `/noticias/${post.slug}`
              : `/news/${post.slug}`}
              className='post__img'
            >
              <img src={post.image.url} alt={post.image.alt} />
            </Link>

            <div className='post__content' >
              <header>
                <h2>{post.title}</h2>

                <Link
                  to={language === 'pt_BR'
                    ? `/categoria/${post.categories[0]?.slug}`
                    : `/category/${post.categories[0]?.slug}`}
                >
                  <h3>{post.categories[0].title}</h3>
                </Link>
              </header>

              <div>
                <Link to={language === 'pt_BR'
                  ? `/noticias/${post.slug}`
                  : `/news/${post.slug}`}>
                  {language === 'en_US'
                    ? 'Read more'
                    : 'Ler mais'}
                </Link>

                <SocialShare
                  slug={language === 'pt_BR'
                    ? `noticias/${post.slug}`
                    : `news/${post.slug}`} />
              </div>
            </div>
          </div>
        ))}
      </CartContainer>
      <Container>
        {posts?.length > 0 && hasMorePost && (
          <button
            className="loading-post"
            onClick={onclick}
            disabled={loading}
          >
            {
              loading
                ? language === 'en_US'
                  ? 'Loading'
                  : 'Carregando'
                : language === 'en_US'
                  ? 'See more news'
                  : 'Ver mais notícias'
            }
          </button>
        )}
      </Container>
    </Fragment>
  );
}

export default CardNew;
