import React from 'react'
import { useLanguage } from '../../hooks/useLanguage'
import "./styles.css"


function CustonCards({ cards, type }) {
  const { language } = useLanguage()

  return (
    <section className="container-cards">
      {cards
        .filter(card => card.type.includes(type))
        .filter(card => !card.chairs[0][type]?.length) // tirando os card chairs
        .filter(card => !card.coChairs[0][type]?.length) // tirando os cards co-chairs
        .filter(card => card.translations === language)
        .map((item) => {
          return (
            <div key={item.id} className="polaroid">
              <img src={item.image.url} className="photo-person" alt={item.image.alt} />
              <div className="caption">
                <div className="content-info">
                  <h3 className="title">
                    {item.title}
                  </h3>
                  <span className="subTitle" title={item.office}>{item.office}</span>
                </div>
                <a className="social-media" href={item.socialMidia} target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29.999" viewBox="0 0 30 29.999">
                    <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M6.715,30H.5V9.971h6.22ZM3.6,7.239A3.619,3.619,0,1,1,7.2,3.6,3.632,3.632,0,0,1,3.6,7.239ZM29.993,30H23.787V20.25c0-2.324-.047-5.3-3.234-5.3-3.234,0-3.729,2.525-3.729,5.136V30H10.611V9.971h5.965V12.7h.087a6.535,6.535,0,0,1,5.885-3.234C28.843,9.469,30,13.614,30,19V30Z" transform="translate(0 -0.001)" fill="#FFFFFF" stroke="#FFFFFF" />
                  </svg>
                </a>
              </div>
            </div>
          )
        })}
    </section>
  )
}

export default CustonCards;
