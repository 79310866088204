import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 2rem;

  h2 {    
    font-family: 'Open Sans', sans-serif;
    font-size:  2.25rem;
    font-weight: 700;
    color: #000;
  }
`;
