// perguntas frequentes
import React, { Fragment } from 'react'
import Accordion from '../../components/Accordion'
import Breadcump from '../../components/Breadcump'
import { useLanguage } from '../../hooks/useLanguage'
import { Container } from '../../styles/PageCommite'
import { Helmet } from "react-helmet";

function Faq() {
  const { language } = useLanguage()
  return (
    <Fragment>
      <Helmet>
        <title>FAQ - Rio Pipeline</title>
        <link rel="canonical" href="/faq" />
      </Helmet>
      <Breadcump />
      <Container>
        <h1>
          {language === 'en_US'
            ? 'Common Questions'
            : 'Perguntas Frequentes'}
        </h1>
        <Accordion />
      </Container>
    </Fragment>
  )
}

export default Faq
