import React, { Fragment, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Breadcump from '../../components/Breadcump'
import { useLanguage } from '../../hooks/useLanguage'
import { GET_SEARCH_RESULTS } from '../../queries/pages'
import { client } from '../../Services/wordpress'
import { Container } from './styles'
import { Helmet } from "react-helmet";

function Seacrh() {
  const { language } = useLanguage()
  const { search } = useLocation()
  const [searchText, setSearchText] = useState('')
  const [data, setData] = useState({
    posts: [],
    term: '',
    after: '',
    per_page: 10,
    hasMorePost: true,
    loadingMorePost: false,
    loading: true,
    firstLoading: true,
    language
  })

  useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search);

      setData(prevState => {
        return {
          ...prevState,
          term: queryParams.get('q').replaceAll('"', '')
        }
      })
    }
  }, [search])

  useEffect(() => {
    setData(prevState => {
      return ({
        ...prevState,
        after: '',
        hasMorePost: true,
        loadingMorePost: false,
        loading: true,
        language
      })
    })
  }, [language])

  useEffect(() => {
    if (data.firstLoading === false) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.language])

  useEffect(() => {
    if (data.term) {
      setData(prevState => {
        return {
          ...prevState,
          loading: true
        }
      })
      getData()
    } else {
      setData(prevState => {
        return {
          ...prevState,
          loading: false
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.term])

  async function getData() {
    try {
      const res = await client.query({
        query: GET_SEARCH_RESULTS,
        variables: {
          term: data.term,
          per_page:
            data.per_page,
          after: data.after,
          language: data.language
        }
      })

      const { data: resquestData, errors } = res

      if (errors) {
        console.error("request falhed")
        return
      }

      const newData = resquestData.posts.nodes.map(post => {
        return {
          id: post.id,
          title: post.title,
          excerpt: post.excerpt,
          categories: post.categories.nodes.map(category => {
            return {
              id: category.categoryId,
              title: category.name,
              slug: category.slug,
            }
          }),
          slug: post.slug,
        }
      })

      setData(prevState => {
        if (prevState.after) {
          return {
            ...prevState,
            posts: [...prevState.posts, ...newData],
            after: resquestData.posts.pageInfo.endCursor,
            hasMorePost: resquestData.posts.pageInfo.hasNextPage,
            loading: false,
            loadingMorePost: false,
            firstLoading: false,
          }
        }

        return {
          ...prevState,
          posts: [...newData],
          after: resquestData.posts.pageInfo.endCursor,
          hasMorePost: resquestData.posts.pageInfo.hasNextPage,
          loading: false,
          loadingMorePost: false,
          firstLoading: false,
        }
      })
    } catch (error) {
      console.error("request fail")
      // console.error(error) // TODO Habilitar somente em degub
    }
  }

  function handleSubmitSearch(event) {
    event.preventDefault();

    // reset
    setData(prevState => {
      return {
        ...prevState,
        after: '',
        setMorePost: false,
        term: searchText
      }
    })
  }

  function handleMorePost() {
    if (data.hasMorePost) {
      setData(prevState => {
        return {
          ...prevState,
          loadingMorePost: true,
        }
      })

      getData()
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? 'Search - Rio Pipeline'
            : 'Busca - Rio Pipeline'
          }
        </title>

        {language === 'en_US'
          ? <link rel="canonical" href="/busca" />
          : <link rel="canonical" href="/search" />
        }
      </Helmet>
      <Breadcump />
      <Container>
        <h1>
          {language === "en_US" ? "Search result for " : "Resultado da busca para "}
          <span>"{data.term}"</span>
        </h1>

        <div className="header">
          <p>
            {language === "en_US" ? "Total of " : "Total de "}
            <strong>{data.posts.length}</strong>
            {language === "en_US" ? " result(s) found." : " resultado(s) encontrado(s)."}

          </p>
          <form onSubmit={handleSubmitSearch}>
            <label>
              <input
                type="text"
                name="q"
                placeholder={language === "en_US" ? "Search" : "Buscar"}
                value={searchText}
                onInput={event => setSearchText(event.target.value)}
                disabled={data.loading}
              />
              <button type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.004" viewBox="0 0 16 16.004"><path d="M20.312,19.339l-4.45-4.492a6.342,6.342,0,1,0-.962.975l4.421,4.462a.685.685,0,0,0,.967.025A.689.689,0,0,0,20.312,19.339Zm-9.433-3.462a5.007,5.007,0,1,1,3.542-1.467A4.977,4.977,0,0,1,10.879,15.876Z" transform="translate(-4.5 -4.493)" /></svg>
              </button>
            </label>
          </form>
        </div>

        {data.loading === true && (<p className="text-message">Loading...</p>)}

        {data.posts.length > 0 && data.loading === false && (
          <ul className="posts-list">
            {data.posts.map(post => (
              <li key={post.id} className="post">
                <div>
                  <header>
                    <Link
                      to={
                        language === 'pt_BR'
                          ? `/noticias/${post.slug}`
                          : `/news/${post.slug}`
                      }
                    >
                      <h2>{post.title}</h2>
                    </Link>
                    <Link
                      to={
                        language === 'pt_BR'
                          ? `/categoria/${post.categories[0]?.slug}`
                          : `/category/${post.categories[0]?.slug}`
                      }

                    >
                      <h3>{post.categories[0]?.title}</h3>
                    </Link>
                  </header>
                  <Link
                    to={language === 'pt_BR'
                      ? `/noticias/${post.slug}`
                      : `/news/${post.slug}`
                    }
                    dangerouslySetInnerHTML={{ __html: post.excerpt }}></Link>
                </div>
              </li>
            ))}
          </ul>
        )}

        {
          data.posts.length > 0 &&
          data.loading === false &&
          data.hasMorePost &&
          <button className="loading-post" onClick={handleMorePost} disabled={data.loadingMorePost || data.loading}>
            {data.loadingMorePost ? 'Carregando' : 'Ver mais notícias'}
          </button>
        }


        {data.posts.length === 0 && data.loading === false && (
          <p className="text-message">Nenhum resultado encontrado.</p>
        )}
      </Container>
    </Fragment>
  )
}

export default Seacrh
