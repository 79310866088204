// Page Commitê Organizador
import React, { Fragment, useEffect } from 'react'
import AuthorCard from '../../components/AuthorCard'
import CustonCards from '../../components/CustonCards'
import { client } from '../../Services/wordpress'
import { useState } from 'react'
import Breadcump from '../../components/Breadcump'

import { Container } from '../../styles/PageCommite'
import { formatedComites } from '../../utils/formatedData'
import { GET_COMMITTEE_ORGANIZING } from '../../queries/pages'
import { useLanguage } from '../../hooks/useLanguage'
import { Helmet } from "react-helmet";

function InternationalCommittee() {
  const { language } = useLanguage()

  const [cards, setCards] = useState([])

  const {data} =  client.query({
    query: GET_COMMITTEE_ORGANIZING
  })

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
   console.log("data",data)
  }, [data])

  async function getData() {
    const res = await client.query({
      query: GET_COMMITTEE_ORGANIZING
    })

    const { data } = res
    const newData = data.comitesOrganizadores.nodes.map(formatedComites)
    setCards(newData)
  }


  return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? '2023 International Committee - Rio Pipeline'
            : 'Comitê Internacional 2023 - Rio Pipeline'
          }
        </title>

        {language === 'en_US'
          ? <link rel="canonical" href="/international-committee" />
          : <link rel="canonical" href="/comite-internacional" />
        }
      </Helmet>
      <Breadcump customPath={
        language === 'en_US'
          ? [{ link: 'international-committee', name: '2023 International Committee' }]
          : [{ link: 'comite-internacional', name: 'Comitê Internacional 2023' }]
      } />
      <Container>
        <h1>{language === 'en_US'
          ? '2023 International Committee'
          : 'Comitê Internacional 2023'}
        </h1>
        <AuthorCard cards={cards} type="internacional" />
        <CustonCards cards={cards} type="internacional" />
      </Container>
    </Fragment>
  )
}

export default InternationalCommittee
