import React from 'react'
import { Fragment } from 'react'
import { useLanguage } from '../../hooks/useLanguage'

import { ContainerTable } from "./styles"
const Subscribe = ({ children }) => {
  const { language } = useLanguage()
  return (
    <ContainerTable>
      <h2>
        {language === 'en_US'
          ? 'Registration open'
          : `Inscrições abertas`}
      </h2>
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                {language === 'en_US'
                  ? 'BRL *'
                  : 'Inscrição'}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {language === 'en_US'
                  ? 'Non Member'
                  : 'Inteira - Não associado'}
              </td>
              <td>
                {language === 'en_US'
                  ? '180 BRL'
                  : 'R$ 180,00'}
              </td>
            </tr>
            <tr>
              <td>
                {language === 'en_US'
                  ? 'IBP Member'
                  : 'Associado IBP'}
              </td>
              <td>
                {language === 'en_US'
                  ? '144 BRL'
                  : 'R$ 144,00'}
              </td>
            </tr>
            <tr>
              <td>
                {language === 'en_US'
                  ? 'Students'
                  : 'Estudantes'}
              </td>
              <td>
                {language === 'en_US'
                  ? '80 BRL'
                  : 'R$ 80,00'}
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          {language === 'en_US'
            ? (
              <Fragment>
                *1 USD = 5,52 BRL (exchange rate on October 6th, access the day's rate at:
                <a href="https://www.bcb.gov.br/en" target="_blank" rel="noopener noreferrer">https://www.bcb.gov.br/en)</a>
              </Fragment>
            )
            : ''}
        </p>
      </div>

      <div className="container-button-table">
        <a
          href="https://eventos.tmp.br/riopipeline2023/visitantes/inicio.php"
          target="_blank"
          rel="noopener noreferrer"
          className="send-tagging"
        >
          <button className="btn-trasparent" href="https://www.ibp.org.br/personalizado/uploads/2021/08/abertura-das-inscricoes-da-rio-pipeline-2021.ics" target="_blank" rel="noreferrer">
            {language === 'en_US'
              ? 'Sign Up'
              : 'Inscreva-se'}
          </button>
        </a>
      </div>
    </ContainerTable>
  )
}

export default Subscribe
