import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

export default function Input({
  label,
  name,
  textClick,
  onclick,
  customClass = '',
  ...rest
}) {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: inputRef.current?.type === 'checkbox' ? 'checked' : 'value',
    })
  }, [fieldName, registerField])

  return (
    <label className={customClass}>
      {label ? <p>{label} {textClick && <span onClick={onclick}>{textClick}</span>}</p> : ''}
      <input ref={inputRef} defaultValue={defaultValue} {...rest} />
      {error && <span className="require">{error}</span>}
    </label>
  )
}
