import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import TagManager from "react-gtm-module";
import * as dotenv from "dotenv";
import { loadTagging } from "./assests/tagging";

dotenv.config();

const tagManagerArgs = {
  gtmId: "GTM-5P6Q6F7",
};

TagManager.initialize(tagManagerArgs);

// loadTagging()

ReactDOM.render(<App />, document.getElementById("root"));