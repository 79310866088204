// incluindo funcao no Yup de comparacao de valores
export function equalTo(ref, msg) {
  return this.test({
    name: "equalTo",
    exclusive: false,
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || "${path} must be the same as ${reference}",
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}

// incluindo funcao no Yup de senha
export function validatePassword(msg) {
  return this.test({
    name: "validatePassword",
    exclusive: false,
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || "${path} must be the same as ${reference}",
    test: (value) => {
      const containsCapitalLetter = new RegExp(/[A-Z]/);
      const containsLowercaseLetter = new RegExp(/[a-z]/);
      const containsNumber = new RegExp(/[0-9]/);
      const conteinsEightOrMoreCharacters = value.length >= 8;

      if (
        containsCapitalLetter.test(value) &&
        containsLowercaseLetter.test(value) &&
        containsNumber.test(value) &&
        conteinsEightOrMoreCharacters
      ) {
        return true;
      } else {
        return false;
      }
    },
  });
}


export function validadeNewPassword(msg) {
  return this.test({
    name: "validadeNewPassword",
    exclusive: false,
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || "${path} must be the same as ${reference}",
    test: (value) => {
      if (value) {
        const containsSpecialCharacters = new RegExp(/\W/);
        const containsCapitalLetter = new RegExp(/[A-Z]/);
        const conteinsEightOrMoreCharacters = value.length >= 8;

        if (
          containsSpecialCharacters.test(value) &&
          containsCapitalLetter.test(value) &&
          conteinsEightOrMoreCharacters
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  });
}

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export const validateImageType = (value) => {
  if (value) {
    let type = value.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    return SUPPORTED_FORMATS.includes(type);
  }
};

export const maskPhone = (input) => {
  const target = input.target;
  let value = target.value;
  let reslt = value.replace(/\D/g, "");
  reslt = reslt.replace(/^0/, "");
  if (reslt.length > 10) {
    reslt = reslt.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (reslt.length > 5) {
    reslt = reslt.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (reslt.length > 2) {
    reslt = reslt.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    reslt = reslt.replace(/^(\d*)/, "($1");
  }

  input.target.value = reslt
}
