import React, { Fragment } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useLanguage } from '../../hooks/useLanguage'
import { ContainerThankYou } from '../../styles/pageThankYou'
import { Helmet } from "react-helmet";

const ThankYouPage = () => {
  const { language } = useLanguage()

  setTimeout(function(){
    window.location.href = "https://eventos.tmp.br/riopipeline2023/visitantes/meuspedidos.php";
  }, 5000);

  return (
    <Fragment>
      <Helmet>
        <title>{language === 'en_US'
                ? 'Thank you'
                : 'Obrigado'} - Rio Pipeline</title>
      </Helmet>
      <Router>
        <Switch>
          <ContainerThankYou>
            <h1>
              {language === 'en_US'
                ? 'Thank you!'
                : 'Obrigado!'}
            </h1>
            <p>{language === 'en_US'
              ? 'Registration successful!'
              : 'Inscrição realizada com sucesso!'}
            </p>
          </ContainerThankYou>
        </Switch>
      </Router>

      <Router>
        <Switch>
          <ContainerThankYou>
            <h1>
              {language === 'en_US'
                ? 'Thank you!'
                : 'Congratulations!'}
            </h1>
            <p>{language === 'en_US'
              ? 'Registration successful!'
              : 'See you in Rio Pipeline 2023!'}
            </p>
          </ContainerThankYou>
        </Switch>
      </Router>
    </Fragment>
  )
}

export default ThankYouPage;