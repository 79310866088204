import React, { Fragment } from 'react'

import { Helmet } from "react-helmet";
import Subscribe from '../../components/Subscribe'
import Breadcump from '../../components/Breadcump'
import { Container } from '../../styles/PageCommite'

import { useLanguage } from '../../hooks/useLanguage'
import { Banner } from './styles';

const SignUp = () => {
  const { language } = useLanguage()

  window.location.href = "https://eventos.tmp.br/riopipeline2023/visitantes/inicio.php";

  return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? 'Sign Up - Rio Pipeline'
            : 'Inscreva-se - Rio Pipeline'
          }
        </title>

        {language === 'en_US'
          ? <link rel="canonical" href="/sign-up" />
          : <link rel="canonical" href="/inscreva-se" />
        }
      </Helmet>
      <Breadcump customPath={
        language === 'en_US'
          ? [{ link: 'sign-up', name: 'Sign up' }]
          : [{ link: 'inscreva-se', name: 'Inscreva-se' }]
      } />
      <Banner>
        <div>
          <h1>{language === 'en_US' ? 'Sign Up' : 'Inscreva-se'}</h1>
        </div>
      </Banner>
      <Container>
        <Subscribe />
      </Container>
    </Fragment>
  )
}

export default SignUp
