import styled from 'styled-components';

export const ContainerPageNotFound = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
   width: 100%;
   height: 70vh;
   color: orange;

   p {
       font-size: 2rem;
      
   }
`;
