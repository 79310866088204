import React, { useEffect, useState } from 'react'

import { Container } from '../../styles/PageNew'
import { Link } from 'react-router-dom'
import Breadcump from '../../components/Breadcump'
import CardNew from '../../components/CardNew'
import { Fragment } from 'react/cjs/react.production.min'
import { client } from '../../Services/wordpress'
import { GET_POSTS } from '../../queries/pages'
import { useLanguage } from '../../hooks/useLanguage'
import SocialShare from '../../components/SocialShare'
import { Helmet } from "react-helmet";

const New = () => {
  const { language } = useLanguage()
  const [data, setData] = useState({
    posts: [],
    after: '',
    per_page: 10,
    hasMorePost: true,
    loadingMorePost: false,
    firstLoading: true,
    language
  })

  useEffect(() => {
    setData(prevState => {
      return ({
        ...prevState,
        after: '',
        hasMorePost: true,
        loadingMorePost: false,
        firstLoading: true,
        language
      })
    })
  }, [language])

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.language])

  async function getData() {
    try {
      const res = await client.query({
        query: GET_POSTS,
        variables: { per_page: data.per_page, after: data.after, language }
      })

      const { data: resquestData } = res

      const newData = resquestData.posts.nodes.map(post => {
        return {
          id: post.id,
          title: post.title,
          slug: post.slug,
          categories: post.categories.nodes.map(category => {
            return {
              id: category.categoryId,
              title: category.name,
              slug: category.slug,
            }
          }),
          image: {
            url: post.featuredImage?.node.mediaItemUrl,
            alt: post.featuredImage?.node.altText,
          },
          translations: post.translations.length > 0 ? post.translations[0].locale : ''
        }
      })

      setData(prevState => {
        if (prevState.after) {
          return ({
            ...prevState,
            posts: [...prevState.posts, ...newData],
            after: resquestData.posts.pageInfo.endCursor,
            hasMorePost: resquestData.posts.pageInfo.hasNextPage,
            loadingMorePost: false,
            firstLoading: false
          })
        }

        return ({
          ...prevState,
          posts: newData || [],
          after: resquestData.posts.pageInfo.endCursor,
          hasMorePost: resquestData.posts.pageInfo.hasNextPage,
          loadingMorePost: false,
          firstLoading: false
        })
      })
    } catch (error) {
      console.error("request fail")
      // console.error(error) // TODO Habilitar somente em degub
    }
  }

  function handleMorePost() {
    if (data.hasMorePost) {
      setData(prevState => {
        return ({
          ...prevState,
          loadingMorePost: true
        })
      })
      getData()
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? 'News - Rio Pipeline'
            : 'Notícias - Rio Pipeline'
          }
        </title>

        {language === 'en_US'
          ? <link rel="canonical" href="/news" />
          : <link rel="canonical" href="/noticias" />
        }
      </Helmet>
      <h1 style={{ position: 'absolute', display: 'none' }}>Rio Pipeline - conference {'&'} exhibition</h1>

      <Breadcump customPath={
        language === 'en_US'
          ? [{ link: 'news', name: 'News' }]
          : [{ link: 'noticias', name: 'Notícias' }]
      } />

      <Container>
        <h2>
          {language === 'en_US'
            ? 'News / Highlights'
            : 'Notícias / Destaques'}
        </h2>

        <div className="content-box-new">
          {data.posts.filter((_, index) => index === 0).map((post) => (
            <div key={post.id} className="box">
              <figure>
                <img src={post.image.url} alt={post.image.alt} />
              </figure>

              <div className="texto">
                <h3>{post.title}</h3>
                <div className="box-resume-new">
                  <Link
                    to={
                      language === 'pt_BR'
                        ? `/noticias/${post.slug}`
                        : `/news/${post.slug}`
                    }
                    className="most-new">
                    {language === 'en_US'
                      ? 'Read more'
                      : 'Ler mais'}
                  </Link>
                  <SocialShare slug={
                    language === 'pt_BR'
                      ? `noticias/${post.slug}`
                      : `news/${post.slug}`
                  } inverte={true} />
                </div>
              </div>
            </div>
          ))}

          <div className="box-group">
            {data.posts.filter((_, index) => index === 1 || index === 2).map(post => (
              <figure key={post.id}>
                <div className="image">
                  <img src={post.image.url} alt={post.image.alt} />
                </div>
                <figcaption>
                  <h3>{post.title}</h3>
                  <div className="box-resume-new">
                    <Link
                      to={
                        language === 'pt_BR'
                          ? `/noticias/${post.slug}`
                          : `/news/${post.slug}`
                      }
                    >
                      {language === 'en_US'
                        ? 'Read more'
                        : 'Ler mais'}
                    </Link>
                    <SocialShare slug={
                      language === 'pt_BR'
                        ? `noticias/${post.slug}`
                        : `news/${post.slug}`
                    } inverte={true} />
                  </div>
                </figcaption>
              </figure>
            ))}
          </div>
        </div>
      </Container>

      <CardNew
        posts={data.posts}
        onclick={handleMorePost}
        loading={data.loadingMorePost}
        hasMorePost={data.hasMorePost}
        firstLoad={data.firstLoading}
        title={language === 'en_US' ? 'Latest news' : 'Ultimas notícias'}
      />
    </Fragment>
  )
}

export default New
