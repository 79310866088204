import styled from 'styled-components';

export const FooterContainer = styled.div`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .newsletter-container {
    width: 100%;
    background-color: #000;
    color: var(--white);

    .container-news {
      width: 100%;
      max-width: 1366px;
      margin: 0 auto;
      padding: 0 2rem;
    }

    .newsletter-content {
        position: relative;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        padding: 1rem 0;

        /* 
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 3px;
            height: calc(100% - 6rem);
            background-color: var(--orange);
        } */

        &__form {
            flex: 1 1 45%;
            margin-right: 5%;

            h2 {
              font-family: 'Open Sans', sans-serif;
              font-size: 1.5rem;
              font-weight: 400;
              line-height: 2rem;
              color: var(--white);
              margin-bottom: 1.5rem;
            }

            h3 {
              font-family: 'Open Sans', sans-serif;
              font-size: 1.1875rem;
              font-weight: 300;
              margin-bottom: 1rem;
            }

            form {
              display: flex;
              align-items: center;

              label {
                  position: relative;
                  flex: 1 1 auto;
                  max-width: 150px;
                  margin-right: 5px;

                  input {
                      width: 100%;                        
                      height: 45px;
                      padding-left: 1rem;
                      border: 0;

                      &::placeholder {
                        font-family: 'Open Sans', sans-serif;
                        font-size: 1rem;
                        font-weight: 300;
                        color: #393939;
                      }

                      &:focus {
                        outline: 1px solid #DEDEDE;
                      }

                      &.required ~  span.require {
                        display: block !important;
                      }
                  }

                  span.require {
                    position: absolute;
                    left: 0;
                    bottom: -50%;

                    font-family: 'Open Sans', sans-serif;
                    font-size: .75rem;
                    color: tomato;
                    text-transform: uppercase;
                  }
              }

              button[type=submit] {
                flex: 1 1 auto;
                max-width: 150px;
                height: 45px;
                margin-left: 0.5rem;

                border: 0;
                background-color: var(--orange);
                color: #000000;

                font-family: 'Open Sans', sans-serif;
                font-size: 1.0032rem;
                font-weight: 700;
                text-transform: uppercase;

                transition: filter .3s;

                &:hover {
                  filter: brightness(.8);
                  background-color: transparent;
                  border: 1px solid  #FCAF17; 
                  color: #FCAF17;
                }
              }
            }            
        }

        &__content {
            flex: 1 1 45%;
            margin-left: 5%;

            h2 {
                font-family: 'Open Sans', sans-serif;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 2rem;
                color: var(--white);
                margin-bottom: 1.5rem;
            }

            p {
                font-family: 'Open Sans', sans-serif;
                font-size: 1.125rem;
                font-weight: 300;
                line-height: 1.75em;
                margin-bottom: 1.5rem;
            }

            ul {
                list-style: none;
                padding: 0;

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;

                li {
                    a {
                        img {
                            display: block;
                        }
                    }

                    & + li {
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
  }

  .footer-link-wrapper {
    width: 1366px;
    max-width: 100%;
    padding: 0 2rem;
    margin: 2rem auto;

    display: flex;
    align-items: center;
    position: relative;

    p {
      font-size: 9px;
      margin-left: -1rem;
    }

    .footer-logo {
      display: flex;
      align-items: center;

      .footer-link-items {

        & + .footer-link-items {
          margin-left: 40px;
        }

        & > .navbar-logo {
          img {
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
        

        .logo-rio {
          img {
            max-width: 230px;
            max-height: 55px;
          }
        }

        .logo-ibp {
          img {
            max-height: 100px;
          }
        }
      }
    }

    .footer-list-path {
      flex: 1 1 auto;
      margin-left: 60px;

      .footer-link-items {
        list-style: none;
        padding: 0;
        margin: 0;

        display: flex;
        flex-direction: column;
        
        li {
          a {
            font-family: 'Open Sans', sans-serif;
            font-size: 0.875rem;
            text-transform: capitalize;
            color: #60605F;
            transition: color .2s ease;

            &:hover {
              color: #FCAF17;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .disabled-people-icon {
      position: absolute;
      right: -120px;

      @media screen and (max-width: 1400px) {
        right: 50px;
      }

      @media screen and (max-width: 920px) {
        top: 5px;
        right: 30px;
      }
    }

  }
  .line {
    width: 100%;
    max-height: 100%;
    height: 1px;
    background-color: #C2C2C2;
  }

  .website-rights {
    width: 1366px;
    max-width: 100%;
    margin: 1rem auto;
    padding: 0 2rem;

    a {
      font-family: 'Open Sans', sans-serif;
      font-size: 0.75rem;
      font-weight: 600;
      color: #000;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 880px) {
    .newsletter-container {
      .newsletter-content {
          display: block;

          &:after {
            display: none;
          }

          &__form {
              margin-right: 0;
              border-bottom: 3px solid var(--orange);
              padding-bottom: 45px;

              h2 {
                  font-size: 2.0064rem;
              }
  
              h3 {
                  font-size: 1rem;
              }

              form {
                  width: 100%;
                  flex-direction: column;
                  align-items: start;

                  label {
                      max-width: 100%;
                      width: 100%;
                      margin-bottom: 1rem;

                      span.require {
                          position: relative;
                      }
                  }

                  button[type=submit] {
                      max-width: 36%;
                      width: 100%;
                      height: 45px;
                      margin: 0;
                  }
              }
          }

          &__content {
            margin-top: 45px;
            margin-left: 0;

            p {                    
              font-size: 1.1704rem;
            }
          }
      }
    }

     
    .footer-link-wrapper {

      flex-direction: column-reverse;

      .footer-list-path {
        width: 100%;
        margin-left: 0;
        margin-bottom: 45px;

        .footer-link-items {
          grid-template-columns: repeat(2, 1fr);
          column-gap: 30px;

        
          li {
            order: 0;

            &:nth-of-type(1) {
              order: 1;
            }            
            &:nth-of-type(2) {
              order: 4;
            }            
            &:nth-of-type(3) {
              order: 7;
            }            
            &:nth-of-type(4) {
              order: 3;
            }            
            &:nth-of-type(5) {
              order: 6;
            }            
            &:nth-of-type(6) {
              order: 9;
            }            
            &:nth-of-type(7) {
              order: 2;
            }            
            &:nth-of-type(8) {
              order: 8;
            }            
            &:nth-of-type(9) {
              order: 12;
            }            
            &:nth-of-type(10) {
              order: 5;
            }            
            &:nth-of-type(11) {
              order: 10;
            }            
            &:nth-of-type(12) {
              order: 11;
            }            
          }
        }
      }
    }
  }


`;

export const ContainerSocialMedia = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 55%;
`
export const SocialMediaIcon = styled.div`
    background: #fff;
    padding: 7px;
    border-radius: 5px;
`


