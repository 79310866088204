export const formatedComites = (comite) => {
  return {
    id: comite.id,
    image: {
      url: comite.featuredImage.node.mediaItemUrl,
      alt: comite.featuredImage.node.altText,
    },
    title: comite.title,
    office: comite.acfFieldsComitesOrganizadores.cargo,
    type: comite.acfFieldsComitesOrganizadores.comite,
    chairs: [
      {
        estrategico: comite.acfFieldsComitesOrganizadores.chairEstrategico,
        internacional: comite.acfFieldsComitesOrganizadores.chairInternacional,
        organizador: comite.acfFieldsComitesOrganizadores.chairOrganizador,
        tecnico: comite.acfFieldsComitesOrganizadores.chairTecnico,
      },
    ],
    coChairs: [
      {
        estrategico: comite.acfFieldsComitesOrganizadores.coChairEstrategico,
        internacional:
          comite.acfFieldsComitesOrganizadores.coChairInternacional,
        organizador: comite.acfFieldsComitesOrganizadores.coChairOrganizador,
        tecnico: comite.acfFieldsComitesOrganizadores.coChairTecnico,
      },
    ],
    description: comite.content,
    socialMidia: comite.acfFieldsComitesOrganizadores.link,
    translations: comite.acfIdioma.idioma,
  };
};

export const formatedExpositors = (expositor) => {
  return {
    id: expositor.id,
    title: expositor.title,
    estande: expositor.acfFieldsExpositores.estande,
    website: expositor.acfFieldsExpositores.website,
    image: {
      url: expositor.acfFieldsExpositores.imagem?.mediaItemUrl || "",
      alt: expositor.acfFieldsExpositores.imagem?.altText || expositor.title,
    },
  };
};
