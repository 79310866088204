import styled from "styled-components";
import RioImage from "../../assests/images/Rio01.jpg";

const slickStyle = {
  ".slick-slider": {
    width: "100%",
    ".slick-dots": {
      bottom: "-36px",
      "li button::before": {
        height: "5px !important",
        backgroundColor: "#000",
        content: "'' !important",
      }
    }
  },
  img: {
    display: "block",
    width: "100%",
    height: "auto"
  }
}

export const ContainerTextWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  alignItems: "center",
  margin: "40px auto",
  padding: "0 2rem",
  maxWidth: "700px",
  ...slickStyle
})

export const ContainerText = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  maxWidth: "600px",
  h2: {
    color: "var(--orange)",
    fontSize: "30px",
    fontWeight: "bold",
    margin: 0
  },
  p: {
    margin: 0,
    fontSize: "18px",
      fontWeight: 300,
      lineHeight: "28px",
      color: "black",
  },
  "@media (max-width: 992px)": {
    h2: {
      fontSize: "1.9rem",
    },
    p: {
      // fontSize: "0.9rem",
    }
  }
})

export const HotelContainerWrapper = styled("div")({
  backgroundColor: "#f19a33",
  margin: "20px 0"
})

export const HotelContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "25px",
  alignItems: "center",
  a: {
    width: "45% !important",
    ".slick-slider": {
      width: "100% !important",
      ".slick-dots": {
        ...slickStyle[".slick-slider"][".slick-dots"],
        bottom: "20px",
      }
  }
  },
  "@media (max-width: 992px)": {
    flexDirection: "column",
    ".slick-slider": {
      width: "100% !important",
    },
    padding: "0 2rem",
    paddingBottom: "2rem"
  }
})

export const HotelContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  alignItems: "center",
  maxWidth: "600px",
  padding: "1rem 0",
  paddingRight: "1rem",
  h2: {
    fontSize: "2.4rem",
    textTransform: "uppercase",
    margin: 0,
    color: "white"
  },
  p: {
    margin: 0,
    color: "white",
    textAlign: "left",
    alignSelf: "start",
    fontSize: "18px",
      fontWeight: 500,
  },
  hr: {
    height: "2px",
    backgroundColor: "#fff",
    opacity: .3,
    width: "60%",
    alignSelf: "baseline",
    margin: "8px 0"
  },
  a: {
    alignSelf: "start",
    marginTop: "20px",
    cursor: "pointer",
    padding: "5px 35px",
    textTransform: "uppercase",
    textAlign: "center",
    transition: "all 0.5s ease 0s",
    backgroundColor: "var(--orange)",
    border: "2px solid var(--orange)",
    color: "white",
    width: "fit-content",
    fontWeight: 600,
  },
  ".logo-contact": {
    display: "flex",
    flexDirection: "column",
    alignSelf: "start",
    img: {
      width: "170px",
      height: "auto"
    },
  },
  "@media (max-width: 992px)": {
    padding: 0
  }
})


export const HotelsWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
  margin: "40px auto",
  alignItems: "baseline",
  maxWidth:"1000px",
  
  ".hotels-title": {
    color: "var(--orange)",
    fontSize: "26px",
    fontWeight: 600,
    margin: 0,
    maxWidth: "60%",
    lineHeight: "32px",
    textAlign: "center",
    padding: "0 1rem",
    margin:"0 auto"
  },
  ".hotels-subtitle": {
    margin:"0 auto",
    padding:" 0 1rem"
  },
  "@media (max-width: 992px)": {
    ".hotels-title": {
      maxWidth: "100%",
      fontSize: "24px",
    },
  }
})

export const HotelItem = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "25px",
  maxWidth: "1025px",
  padding: "0 2rem",
  ".hotel-item-image": {
    width: "429px",
    height: "auto",
  },
  ".hotel-info": {
    display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    gap: "10px",
    ".hotelTitle": {
      fontSize: "23px",
      fontWeight: "bold",
      color: "var(--orange)",
      ":hover a": {
        color: "var(--orange)",
      }
    },
    ".contactHotel": {
      a: {
        textDecoration: "underline"
      }
    },
    p: {
      fontSize: "18px",
      fontWeight: 300,
      lineHeight: "28px",
      color: "black",
    },
    ".linkToHotel": {
        cursor: "pointer",
        padding: "5px 35px",
        textTransform: "uppercase",
        textAlign: "center",
        transition: "all 0.5s ease 0s",
        backgroundColor: "transparent",
        border: "2px solid var(--orange)",
        color: "var(--orange)",
        width: "fit-content",
        fontWeight: 600,
        ":hover": {
          backgroundColor: "var(--orange)",
          color: "white"
        }
      },
  },
  "@media (max-width: 992px)": {
    flexDirection: "column",
    gap: "10px",
  },
  "@media (max-width: 650px)": {
    ".hotel-item-image": {
      maxWidth: "429px",
      width: "100%"
    },
  }
})


export const Banner = styled("div")({
  width: "100%",
  minHeight: "200px",
  backgroundImage: `url(${RioImage})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem",
  div: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "725px",
    minHeight: "100px",
    padding: "20px",
    width: "100%",
    span: {
      color: "rgb(255, 255, 255)",
      fontSize: "2.25rem",
      fontWeight: 700,
      textShadow: "1px 1px 2px var(--orange), 0 0 1em var(--orange), 0 0 0.2em var(--orange)"
    }
  }
})
