import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #FCAF17;

  .topbar {
    width: 100%;
    height: 100%;
    max-width: 1366px;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .topbar-list {
      list-style: none;
      padding: 0;
      margin: 0;

      display: flex;
      align-items: center;

      &__item {
        position: relative;
        display: flex;
        align-items: center;

        & + .topbar-list__item {
          &::before {
            content: '';
            display: block;
            width: 1px;
            height: 15px;
            background-color: #000;
            margin: 0 10px;
          }                    
        }

        span {
          cursor: pointer;
        }

        &--img {
          span {
            cursor: pointer;

            img {
              display: block;
              width: 20px;
              height: 15px;
            }

            & + span {
              margin-left: 20px;
            }
          }
        }

        .form-search {
          position: relative;
          width: 30px;
          height: 30px;
          background: transparent;
          border: 1px solid transparent;

          input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: none;
            padding-left: 15px;
            visibility: hidden;
            transition: all .2s linear;
          }

          button {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
            background: none;
            border: none;
          }

          transition: all .3s ease;

          &.active {
            width: 320px;
            background: #fff;
            border-color: #707070;

            input {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: auto; 
    font-size: 0.75rem;   
    color: black;
    font-family: 'Open Sans', sans-serif;
  }

  .title p {
    font-size: 13px;    
  }

  .topbar-form {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;

    display: flex;
    justify-content: center;

    width: 600px;
    max-width: 100%;
    height: 100%;
    background-color: #000;
    border-left: 6px solid var(--orange);

    overflow: hidden;
    overflow-y: auto;

    transform: translateX(100%);
    opacity: 0;
    transition: all .3s ease;

    &.active {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 990px) {
    .topbar-form {
      border: none;
      padding: 0 2rem;
    }

    .topbar {
      .topbar-list {
        &__item {
          display: none;

          &:first-child {
            display: block;

            .form-search {
              &.active {
                width: 188px;
              }
            }
          }

        

          &--img {
            display: flex;
          }
          & .faq {
            display: flex;
          }

          &.fale-conosco {
            display: none !important;
          }
        }
      }
    }
  }
`
