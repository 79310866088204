import styled from 'styled-components';

export const ContainerSponsors = styled.section`
  width: 1000px;
  max-width: 100%;
  padding: 0 2rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;

  h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 2.25rem;
    font-weight: 700;
    text-align: center;
    margin: 40px 0;
  }

  .patrocinadores-fullimage{
    display:flex;
    max-width:1000px;
    width:100%;
  }

  .box-sponsors {
    width: 100%;
    max-width: 80%;

    h3 {
      font-family: 'Open Sans', sans-serif;
      font-size: 1.1704rem;
      text-align: center;
      border-bottom: 1px solid #000;
      padding: 1.125rem 0;
      margin-bottom: 2rem; 
      color: #000000;
    }

    .brands-sponsors {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      a {
        display: block;
        margin: 0 15px;
        margin-bottom: 20px;

        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      &.patrocinio-platinum {
        a {
          img {
            max-width: 440px;
            max-height: 90px;
          }
        }
      }

      &.patrocinio-ouro {
        a {
          img {
            max-width: 440px;
            max-height: 90px;
          }
        }
      }

      &.patrocinio-prata {
        a {
          img {
            max-width: 215px;
            max-height: 80px;
          }
        }
      }

      &.patrocinio-bronze {
        a {
          img {
            max-width: 190px;
            max-height: 50px;
          }
        }
      }

      &.apoios {
        a {
          img {
            max-width: 120px;
            max-height: 45px;
          }
        }
      }

      &.parceria-institucional {
        a {
          img {
            max-width: 120px;
            max-height: 45px;
          }
        }
      }
    }
  }

  @media (max-width: 990px) {
    h2 {
      font-size: 2.0064rem
    }
    .box-sponsors {
      width: 100%;
      max-width: 100%;

      .brands-sponsors {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        a {
          display: block;
          margin: 0 15px;
          margin-bottom: 20px;

          img {
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }

        &.patrocinio-platinum {
          display: grid;
          grid-template-columns: repeat(2, auto);

          a {
            img {
              max-width: 100%;
              max-height: 90px;
            }
          }
        }

        &.patrocinio-ouro {
          display: grid;
          grid-template-columns: repeat(2, auto);

          a {
            img {
              max-width: 100%;
              max-height: 80px;
            }
          }
        }

        &.patrocinio-prata {
          display: grid;
          grid-template-columns: repeat(2, auto);

          a {
            img {
              max-width: 100%;
              max-height: 60px;
            }
          }
        }

        &.patrocinio-bronze {
          display: grid;
          grid-template-columns: repeat(2, auto);

          a {
            img {
              max-width: 100%;
              max-height: 50px;
            }
          }
        }

        &.apoios {
          display: grid;
          grid-template-columns: repeat(4, auto);

          a {
            img {
              max-width: 100%;
              max-height: 45px;
            }
          }
        }

        &.parceria-institucional {
          display: grid;
          grid-template-columns: repeat(4, auto);

          a {
            img {
              max-width: 100%;
              max-height: 45px;
            }
          }
        }
      }
    }
  }

`