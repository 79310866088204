import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useLanguage } from '../../hooks/useLanguage'

import { SUBMIT_FORM_CONTACT } from '../../queries/gravitForm';
import { client } from '../../Services/wordpress';
import { maskPhone } from "../../utils/validations";
import InputTextarea from '../InputTextarea';
import Input from '../Input'
import * as Yup from "yup";
import { Form } from "@unform/web";

import { ContainerForm } from "./styles"
import InputSelectDinamic from '../InputSelectDinamic';


function FormTop({ onclick }) {
  const { language } = useLanguage()

  const formRef = useRef(null);
  const [submitForm, setSubmitForm] = useState(false)

  async function handleSubmitForm(data) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required(`${language === "pt_BR" ? "Campo obrigatório" : "Required field"}`),
        email: Yup.string()
          .email(`${language === "pt_BR" ? "E-mail inválido" : "Invalid e-mail"}`)
          .required(`${language === "pt_BR" ? "Campo obrigatório" : "Required field"}`),
        subject: Yup.string().required(`${language === "pt_BR" ? "Campo obrigatório" : "Required field"}`),
        phone: Yup.string().required(`${language === "pt_BR" ? "Campo obrigatório" : "Required field"}`),
        message: Yup.string().required(`${language === "pt_BR" ? "Campo obrigatório" : "Required field"}`),
      })

      await schema.validate(data, {
        abortEarly: false,
      });

      regristreForm(data)
      formRef.current.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function regristreForm(data) {
    setSubmitForm(true)

    // TODO verificar query
    await client.mutate({
      mutation: SUBMIT_FORM_CONTACT,
      variables: { ...data }
    })
      .then(_ => {
        const mesagem = language === 'pt_BR'
          ? "Formulário enviado com sucesso."
          : 'Form submitted successfully.'

        alert(mesagem)
        setSubmitForm(false)
        clearForm();
      })
      .catch(err => {
        const mesagem = language === 'pt_BR'
          ? "Ocorreu um erro ao enviar o formulário."
          : 'An error occured when submitting the form.'

        alert(mesagem)
        setSubmitForm(false)
        console.error(err)
      })
  }

  function clearForm() {
    formRef.current.setFieldValue('name', "");
    formRef.current.setFieldValue('email', "");
    formRef.current.setFieldValue('subject', "");
    formRef.current.setFieldValue('phone', "");
    formRef.current.setFieldValue('message', "");
  }

  return (

    <ContainerForm >
      <div className="content-form">
        <div className="nav-menu">
          <Link to='#' className='menu-bars'>
            <p onClick={onclick}>
              {language === 'en_US'
                ? 'Close'
                : 'Fechar'}
            </p>
          </Link>
        </div>
        <Form
          ref={formRef}
          className="contact"
          onSubmit={handleSubmitForm}
          name='contactPatners'
        >
          <span className="title">
            {language === 'en_US'
              ? 'Contact us'
              : 'Fale conosco'}
          </span>

          <Input
            name="name"
            type="name"
            label=
            {language === 'en_US'
              ? 'Full name'
              : 'Nome completo'}
          />
          <Input
            name="email"
            type="email"
            label={language === 'en_US'
              ? 'Email'
              : 'E-mail'}
          />
          <Input
            type='text'
            label={language === 'en_US'
              ? 'Telephone'
              : 'Telefone'}
            name='phone'
            onChange={maskPhone}
            maxLength="15"
          />

          <InputSelectDinamic
            data={[
              { id: "1", name: language === "pt_BR" ? "Congresso" : "Congress", value: language === "pt_BR" ? "Congresso" : "Congress" },
              { id: "2", name: language === "pt_BR" ? "Dúvidas" : "Doubts", value: language === "pt_BR" ? "Dúvidas" : "Doubts" },
              { id: "3", name: language === "pt_BR" ? "Inscrição" : "Enrollment", value: language === "pt_BR" ? "Inscrição" : "Enrollment" },
              { id: "4", name: language === "pt_BR" ? "Parocínio" : "Partnership", value: language === "pt_BR" ? "Parocínio" : "Partnership" },
            ]}
            label={language === "en_US" ? "Subject" : "Assunto"}
            name='subject'
            optionType={language === "pt_BR" ? "Selecione uma opção" : "Select an option"}
          />
          <InputTextarea
            className="message"
            name="message"
            label={language === 'en_US'
              ? 'Message'
              : 'Mensagem'}
          />
          <button
            name="submit"
            type="submit"
            className="contact-submit"
            disabled={submitForm}
          >
            {submitForm ? 'Enviando...' : 'Enviar'}
          </button>
        </Form>
      </div>
    </ContainerForm >
  )
}

export default FormTop
