export const formattedDate = (date, locale = 'pt-br') => {
  try {
    const newDate = new Date(
      date
        .replaceAll("/", "-")
        .split("-")
        .reverse()
        .join("-") + "T00:00:00"
    )
      .toLocaleDateString(
        locale,
        {
          weekday: 'long',
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        })
      .split(",")
      .map(string => string.split(" de "))
      .flat(Infinity)
      .map(
        string => string.replace(".", "").trim()
      )
      .map(string => string.split(" "))
      .flat(Infinity)

    if (locale === 'pt-br') {
      return {
        weekday: newDate[0],
        day: newDate[1],
        month: newDate[2],
        year: newDate[3],
      }
    } else {
      return {
        weekday: newDate[0],
        day: newDate[2],
        month: newDate[1],
        year: newDate[3],
      }
    }
  } catch (error) {
    return date
  }

}