import React from 'react'
import { Parallax } from 'react-parallax';


import { Container, Content } from './styles'

import image from '../../assests/images/main-banner.png';


const BannerParallax = ({ children }) => {
  return (
    <Container>

      <Parallax bgImage={image} strength={500}>
        <div className="container_parallax">
          <Content>
            {children}
          </Content>
        </div>

      </Parallax>

    </Container>
  )
}

export default BannerParallax;
