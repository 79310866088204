import React, { useState, useEffect } from 'react'

import logo from '../../assests/Imagens_IBP/Home/logo-pipeline-black.png';
import Topbar from '../TopBar';
import { Link, NavLink } from 'react-router-dom';

import { useSideBar } from '../../hooks/useSidebar';

import { Header } from './styles'
import { useLanguage } from '../../hooks/useLanguage';

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

export default function NavBarMain({ data }) {
  const { language } = useLanguage()
  const [menu, setMenu] = useState([])
  const [getMenu, setGetMenu] = useState('header-desktop')
  const [toggle, setToggle] = useState(false)
  const { showSidebar } = useSideBar()

  function handleToggleSidebar(event) {
    const button = event.currentTarget

    if (button.text === 'fale conosco' || button.text === 'contact us') {
      showSidebar()
    }
  }

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 1000)

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  useEffect(() => {
    if (dimensions?.width <= 1024) {
      if(language === "pt_BR") {
      setGetMenu('header-mobile')
      } else {
        setGetMenu('header-mobile-ingles')
      }
    } else {
      if(language === "pt_BR") {
        setGetMenu('header-desktop')
      } else {
        setGetMenu('header-desktop-ingles')
      }
      
    }
  }, [dimensions.width, language])

  useEffect(() => {
    if (data) {
      const menu = data[getMenu]

      if (menu) {
        setMenu(menu)
      }
    }
  }, [data, language, getMenu])

  useEffect(() => {
    const body = document.querySelector('body')

    if (toggle) {
      if (body) {
        body.style.overflow = 'hidden'
        body.style.height = '100vh'
      }
    } else if (body) {
      body.style.overflow = ''
      body.style.height = ''
    }
  }, [toggle])

  return (
    <>
      <Topbar />
      <Header>
        <div className="header-nav">
          <Link to="/"><img id="logo" src={logo} alt="rio pipeline" /></Link>
          <div className="nav-bar-toggle mobile" onClick={() => setToggle(!toggle)}>
            <i className={toggle ? 'fas fa-times' : 'fas fa-bars'} />
          </div>

           <nav className={`nav-bar ${toggle ? 'show' : 'hidden'}`}>
            <ul>
              {menu.map((el, index) => (
                <li key={index.toString()} className={`nav-item ${el.url ? '' : 'haveSubItems'}`}>
                  <a
                    href={el.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        const classes = el?.classes?.toString()?.replace(/,/g, " ");

                        if(classes && classes.indexOf("send-tagging") !== -1){

                          try{
        
                            //Traking do facebook
                            window.fbq('track', 'PageView');
                            
                            //Traking do linkedin
                            window.lintrk('track', { conversion_id: 13600961 });
                    
                            //Traking do google
                            function gtag_report_conversion(url) {
                                var callback = function () {
                                  if (typeof (url) != 'undefined') {
                                    window.location = url;
                                  }
                                };
                                window.gtag(
                                  'event', 'conversion', {
                                  'send_to': 'AW-11147243671/9wZ3CP_zk6AYEJfhtcMp',
                                  'transaction_id': '',
                                  'event_callback': callback
                                });
                    
                                return false;
                            }
                            gtag_report_conversion(window.location.href);
                    
                          }catch(e){
                    
                            console.error(e);
                          
                          }

                          setToggle(false);

                        }
                      }
                    }
                    className= {el?.classes !== '' ? el?.classes?.toString()?.replace(/,/g, " ") + ' nav-links'  : 'nav-links'}
                  >
                    {el.title?.toLowerCase()}
                  </a>

                  {el?.subItens?.length > 0 && (
                    <ul className="sub-item">
                      {el.subItens.map((subItem, index) => (
                        <li key={index.toString}>
                          <Link
                            to={subItem.url}
                            onClick={() => setToggle(false)}

                          >
                            {subItem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav> 
        </div>
      </Header>
    </>
  )
}