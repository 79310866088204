import styled from "styled-components";

export const Container = styled.section`
font-family: 'Open Sans', sans-serif;

.content-path {
   width: 30%;
   height: 10vh;
   margin-top: 1rem;
   margin-left: 3rem;

   a {
    color: #000;
    padding: 0.5rem 0.5rem;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    
  
   }
   a:hover {
       text-decoration: underline;
   }
   li {
    list-style: none;
   }
  
   h1 {
       font-size: 3rem;
       font-weight: 700 bold;
   }

   @media(max-width: 420px) {
width: 30rem;

}
@media(max-width: 320px) {
    width: 20rem;
    
}
}




`

