import React from "react";
import { useLanguage } from "../../hooks/useLanguage";
import {
  ContainerTextWrapper,
  ContainerText,
  HotelContainerWrapper,
  HotelContainer,
  HotelContent,
  HotelsWrapper,
  HotelItem,
  Banner,
} from "./styles";
import Breadcump from '../../components/Breadcump'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PartnerEvents() {
  const { language } = useLanguage();

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <Breadcump customPath={
        language === 'en_US'
          ? [{ link: 'know-the-city', name: 'Know the City' }]
          : [{ link: 'conheca-a-cidade', name: 'Conheça a Cidade' }]
      } />
      <HotelsWrapper>
        <p className="hotels-title">
          {language === "en_US"
            ? `Partner events`
            : `Eventos paralelos`}
        </p>
        <span className="hotels-subtitle">
          {language === "en_US"
            ? `Find out about the events that will take place in parallel with the Rio Pipeline congress.`
            : `Conheça os eventos que vão acontecer paralelamente ao congresso da Rio Pipeline `}
        </span>
        <HotelItem>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a>Rio Pipeline Hour </a>
            </span>
            <p>
              <span>
                <strong>
                O coquetel de networking da Rio Pipeline
                </strong>
              </span>
              <br />
              {language === "en_US" ? (
                <strong>Address: </strong>
              ) : (
                <strong>Endereço: </strong>
              )}
              08/08 às 19:30, na torre anexa ao EXPOMAG
            </p>
            <a
              className="linkToHotel"
              href="https://eventos.tmp.br/riopipeline2023/visitantes/inicio.php?pg=17"
            >
              {language === "en_US" ? "Look Now" : "Veja Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a>Introdução a atividades de pigagem, lançamento / recebimento de pigs para jovens profissionais da área de dutos (Young Pipeline Professionals)  </a>
            </span>
            <p>
              <span>
                <strong>
                Oferecido pelos membros do PPSA em parceria com o CTDUT
                </strong>
              </span>
              <br />
              {language === "en_US" ? (
                <strong>Data: </strong>
              ) : (
                <strong>Data: </strong>
              )}
              07/08 
              <br />
              {language === "en_US" ? (
                <strong>Address: </strong>
              ) : (
                <strong>Endereço: </strong>
              )}
              Centro de Tecnologia de Dutos do CTDUT , Rua Ingá , 997 - Campos Elíseos - Duque de Caxias - Rio de Janeiro - RJ - CEP: 25225-763.
            </p>
            <a
              className="linkToHotel"
              href="https://ibpoleoegas-my.sharepoint.com/personal/mariana_marzoa_ibp_org_br/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fmariana%5Fmarzoa%5Fibp%5Forg%5Fbr%2FDocuments%2FRio%20Pipeline%2FIntroduction%20to%20Pigging%20and%20Pig%20Launching%20%26%20receiving%20programme%20ptbr%2Epdf&parent=%2Fpersonal%2Fmariana%5Fmarzoa%5Fibp%5Forg%5Fbr%2FDocuments%2FRio%20Pipeline&ga=1"
            >
              {language === "en_US" ? "CHECK THE SCHEDULE" : "CONFIRA A PROGRAMAÇÃO"}
            </a>
            <a
              className="linkToHotel"
              href="https://ppsa-online.com/ypp"
            >
              {language === "en_US" ? "SIGN UP" : "INSCREVA-SE"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a>Programação YPP </a>
            </span>
            <p>
              <br />
              {language === "en_US" ? (
                <strong>Data: </strong>
              ) : (
                <strong>Data: </strong>
              )}
              08 a 10/08
              <br />
              {language === "en_US" ? (
                <strong>Address: </strong>
              ) : (
                <strong>Endereço: </strong>
              )}
              Sala YPP (Mezanino – EXPOMAG)
            </p>
            <a
              className="linkToHotel"
              href="https://ibpoleoegas-my.sharepoint.com/personal/mariana_marzoa_ibp_org_br/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fmariana%5Fmarzoa%5Fibp%5Forg%5Fbr%2FDocuments%2FRio%20Pipeline%2FPrograma%C3%A7%C3%A3o%20sala%20YPP%2Epdf&parent=%2Fpersonal%2Fmariana%5Fmarzoa%5Fibp%5Forg%5Fbr%2FDocuments%2FRio%20Pipeline&ga=1"
>
              {language === "en_US" ? "CHECK THE SCHEDULE" : "CONFIRA A PROGRAMAÇÃO"}
            </a>
            <a
              className="linkToHotel"
              href="https://eventos.tmp.br/riopipeline2023/visitantes/inicio.php?pg=15"
            >
              {language === "en_US" ? "SIGN UP" : "INSCREVA-SE"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a>Arena Pipeline Experience </a>
            </span>
            <p>
              <br />
              {language === "en_US" ? (
                <strong>Data: </strong>
              ) : (
                <strong>Data: </strong>
              )}
              08 a 10/08
              <br />
              {language === "en_US" ? (
                <strong>Address: </strong>
              ) : (
                <strong>Endereço: </strong>
              )}
              exposição da Rio Pipeline (2º andar EXPOMAG)
            </p>
            <a
              className="linkToHotel"
              href="https://ibpoleoegas-my.sharepoint.com/personal/mariana_marzoa_ibp_org_br/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fmariana%5Fmarzoa%5Fibp%5Forg%5Fbr%2FDocuments%2FRio%20Pipeline%2FARENA%20%28150%C2%A0%C3%97%C2%A0200%C2%A0mm%29%2DLAPTOP%2DKOI9PS96%2Epdf&parent=%2Fpersonal%2Fmariana%5Fmarzoa%5Fibp%5Forg%5Fbr%2FDocuments%2FRio%20Pipeline&ga=1"
>
              {language === "en_US" ? "CHECK THE SCHEDULE" : "CONFIRA A PROGRAMAÇÃO"}
            </a>
            <a
              className="linkToHotel"
              href="https://eventos.tmp.br/riopipeline2023/visitantes/inicio.php?pg=15"
            >
              {language === "en_US" ? "SIGN UP" : "INSCREVA-SE"}
            </a>
          </div>
        </HotelItem>
      </HotelsWrapper>
    </>
  );
}

export default PartnerEvents;
