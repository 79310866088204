import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10%;
    margin: 80px 15%;

    @media screen and (max-width: 990px) {
        margin: 80px 10%;
        flex-direction: column-reverse;
        gap: 30px;
    }

    .container-content {
        max-width: 45%;

        @media screen and (max-width: 990px) {
            max-width: 100%;
        }

        h2 {
            font-weight: bold;
            font-size: 30px;
            font-family: 'Open Sans', sans-serif;
            color: black;

            @media screen and (max-width: 990px) {
                font-size: 24px;
            }
        }

        p {
            font-weight: normal;
            font-size: 18px;
            font-family: 'Open Sans', sans-serif;
            color: black;
        }
    }

    .container-banner {
        max-width: 45%;

        @media screen and (max-width: 990px) {
            max-width: 100%;
        }
    }
`

export const CTAButton = styled.a`
    width: fit-content;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 48px;
    min-width: 222px;
    padding: 0 20px;
    font-style: normal;
    font-weight: 600;
    font-family: "Open Sans";
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    line-height: 64px;
    transition: all 0.5s ease 0s;
    background-color: transparent;
    border: 2px solid var(--orange);
    color: var(--orange);

    @media (max-width: 990px) {
        width: 100%;
        font-size: 16px;
    }

    &:hover {
        background-color: var(--orange);
        color: var(--white);
    }
`