import React, { useEffect } from 'react'
import { useLanguage } from '../../hooks/useLanguage'
import './styles.css'

const Cookie = () => {
  const { language } = useLanguage()
  function cookiePolicyHandler() {
    localStorage.setItem('cookies-accepted', true)
    document.getElementById('cookie-policy').style.display = 'none'
  }

  useEffect(() => {
    var cookieDiv = document.getElementById('cookie-policy')

    if (JSON.parse(localStorage.getItem('cookies-accepted')) === true) {
      cookieDiv.style.display = 'none'
    }

    else {
      cookieDiv.style.display = 'show'
    }
  }, [])

  return (
    <div className="cookie-section" id="cookie-policy">
      <div className="cookie-policy">
        <div className="cookie-policy-info">
          <div className="cookie-policy_text">
            <span>
              {language === 'en_US'
                ? 'Hi! We have cookies on this site to help with better navigation and to understand how you use our page. To learn more about our privacy policy, '
                : `Olá! Nós usamos cookies neste site para ajudar em uma melhor navegação e para entender
                  como você utiliza a nossa página. Para saber mais sobre nossa política de privacidade, `}

              <a href="https://www.ibp.org.br/termos-e-condicoes/" target="_blank" rel="noreferrer">
                {language === 'en_US'
                  ? 'look here. '
                  : 'veja aqui. '}
              </a>

              {language === 'en_US'
                ? 'By continuing to browse the site, you agree to our data usage policy.'
                : 'Ao continuar navegando pelo site, você está de acordo com a nossa política de uso de dados.'}
            </span>
          </div>
          <div className="btn_wrap">
            <button className="btn accept-link" onClick={cookiePolicyHandler}>
              {language === 'en_US'
                ? 'Accept'
                : 'Aceitar'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Cookie
