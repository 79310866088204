import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

export default function InputTextarea({ label, name, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <label>
      {label ? <p>{label}</p> : ""}
      <textarea ref={inputRef} defaultValue={defaultValue} {...rest} />
      {error && <span className='require'>{error}</span>}
    </label>
  );
}
