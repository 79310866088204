import styled from "styled-components"

export const Container = styled.section` 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const CatainerImg = styled.div`
    display: flex;
    align-items: center;
    padding: 0 69px;
    justify-content: center;
    @media (max-width: 768px) {
        padding: 16px;
  }
`
export const ImgMapa = styled.img`
max-width: 100%;
`