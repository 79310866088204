import React, { Fragment } from 'react';
import { useLanguage } from '../../hooks/useLanguage';
import { ContainerThankYou } from '../../styles/pageThankYou';
import { Helmet } from "react-helmet";

const ThankYouPageExpo = () => {
  const { language } = useLanguage();

  setTimeout(function () {
    window.location.href = "https://eventos.tmp.br/riopipeline2023/visitantes/meuspedidos.php";
  }, 5000);

  return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? 'Thank you'
            : 'Obrigado'} - Rio Pipeline
        </title>
      </Helmet>

      {/* Conteúdo sem o cabeçalho e rodapé */}
      <div>
        <ContainerThankYou>
          <h1>
            {language === 'en_US'
              ? 'Thank you!'
              : 'Obrigado!'}
          </h1>
          <p>
            {language === 'en_US'
              ? 'Registration successful!'
              : 'Inscrição realizada com sucesso!'}
          </p>
        </ContainerThankYou>

        <ContainerThankYou>
          <h1>
            {language === 'en_US'
              ? 'Thank you!'
              : 'Congratulations!'}
          </h1>
          <p>
            {language === 'en_US'
              ? 'Registration successful!'
              : 'See you in Rio Pipeline 2023!'}
          </p>
        </ContainerThankYou>
      </div>
    </Fragment>
  );
};

export default ThankYouPageExpo;
