import React from 'react'
import { ContainerAuthor } from './styles'

import icon from "../../assests/Imagens_IBP/Comites/iconLinkedin.png"
import { useLanguage } from '../../hooks/useLanguage'

const AuthorCard = ({ cards, type }) => {
  const { language } = useLanguage()

  return (
    <ContainerAuthor>
      {cards
        .filter(card => card.type.includes(type))
        .filter(card => card.chairs[0][type] || card.coChairs[0][type])
        .filter(card => card.translations === language)
        .map((item, index) => {
          return (
            <div key={item.id} className="content-title">
              <figure>
                <img src={item.image.url} className="photo-person" alt={item.image.alt} />
              </figure>

              <div className="content">
                <h2>{item.title}</h2>
                <span>{item.office}</span>
                <h3>{index === 0 ? 'CHAIR' : 'CO-CHAIR'}</h3>
                <div className="reverse">
                  <div className="content-description" dangerouslySetInnerHTML={{ __html: item.description }} />
                  <a href={item.socialMidia} target="_blank" rel="noopener noreferrer">
                    <img className="img-icon" src={icon} alt="logo rede social" />
                  </a>
                </div>
              </div>
              <div className="content-mobile" dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          )
        })}
    </ContainerAuthor>
  )
}

export default AuthorCard;
