import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom';

import Header from '../components/Header'
import ScrollTopTop from "../components/ScrollTopTop";
import Footer from '../components/Footer';
import Cookie from "../components/CookiePolicyHandle";
import axios from 'axios';

//import { client } from '../Services/wordpress'
//import { GET_MENUS } from '../queries/menus';

export default function Layout({ children }) {
  const [menus, seteMenus] = useState([])

  useEffect(() => {
    getDate()
  }, [])

  async function getDate() {
    const res = await axios.get(`${process.env.REACT_APP_URL_BASE_PROD}/wp-json/wp/menus`)

    if(res.data) {      
      seteMenus(res.data)
    } else {
      seteMenus([])
    }
  }

  //Não exibir header e footer na página de obrigado, solicitação feita pelo cliente para rastreamento
  const isThankYouPage = window.location.pathname === '/obrigado' || window.location.pathname === '/exposicao/obrigado';
  const shouldRenderHeaderFooter = !isThankYouPage;

  return (
    <Router>
      {shouldRenderHeaderFooter && <Header data={menus} />}
      {children}
      <ScrollTopTop />
      {shouldRenderHeaderFooter && <Footer data={menus} />}
      <Cookie />
    </Router>
  )
}