import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    margin-bottom: 30px;
`

export const ExpositorListing = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    gap: 5%;
    row-gap: 30px;

    @media screen and (max-width: 990px) {
        flex-direction: row;
    }

    .expositor-item {
        width: 30%;
        border-bottom: 1px solid #F19B34;
        display: flex;
        align-items: center;
        gap: 5%;
        padding-bottom: 10px;
        
        @media screen and (max-width: 990px) {
            width: 100%;
        }

        &__content {
            p {
                margin: 0;
            }
        }

        &__name {
            font-size: 16px;
            font-weight: bold;
            font-family: 'Open Sans', sans-serif;
            color: #F19B34;
        }

        &__box {
            font-size: 16px;
            font-weight: normal;
            font-family: 'Open Sans', sans-serif;
            color: #414042;
        }

        &__website {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 16px;
            font-weight: normal;
            font-family: 'Open Sans', sans-serif;
            color: #8E8E8E;
            
            span {
                text-decoration: underline;
            }
        }

        img {
            width: 40%;
        }
    }
`

export const PaginationContainer = styled.div`

    ul {
        width: 60%;
        margin: 40px auto 20px;
        list-style: none;
        display: flex; 
        justify-content: center;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 990px) {
            width: 100%;
        }
 
        li {
            font-size: 16px;
            font-weight: bold;
            color: #414042;

            &.selected {
                color: #F19B34;
            }
        }

        .previous, .next {
            color: #414042;
            font-size: 16px;
            font-weight: bold;
            font-family: 'Open Sans', sans-serif;
            border: 1px solid #BBBBBB;
            padding: 5px;
        }
    }
`

export const SearchBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    button {
        color: #212529;
        border: 1px solid #BBBBBB;
        font-size: 16px;
        font-family: 'Trebuchet MS', sans-serif;
        font-weight: normal;
        background: none;
        height: 36px;
        padding: 0 10px;
    }

    input {
        color: #212529;
        border: 1px solid #BBBBBB;
        font-size: 16px;
        font-family: 'Trebuchet MS', sans-serif;
        font-weight: normal;
        background: none;
        height: 36px;
        padding: 0 10px;
    }
`