import React, { useState } from 'react';

import { Button } from './styles';

import Img from '../../assests/Imagens_IBP/Home/topo.png'


const ScrollTopTop = () => {

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <Button className={visible ? 'show' : 'hidden'}>
      <img
        src={Img}
        onClick={scrollToTop}
        alt="seta"
      />
    </Button>
  );
}

export default ScrollTopTop;
