import styled from "styled-components";

export const Bread = styled.ol`
  width: 1366px;
  max-width: 100%;
  margin: 1rem auto;
  padding: 0 2rem;
  list-style: none;

  display: flex;
  align-items: center;

  li {
    display: flex;

    & + li {
      &:before {
        content: '>';
        margin: 0 5px;
        font-family: 'Open Sans', sans-serif;
        font-size: 0.75rem;
        color: #000;
      }
    }

    a {
      display: inline-block;
      font-family: 'Open Sans', sans-serif;
      font-size: 0.75rem;
      text-decoration: underline;
      color: #000;

      &:hover {
        color: var(--orange);
      }

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
`