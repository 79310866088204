import { gql } from '@apollo/client'

export const SUBMIT_FORM_SPONSORS = gql`
  mutation SubmitFormSponsors(
    $name: String
    $email: String 
    $phone: String
    $message: String
    $checkbox: String
  ) {
    submitGravityFormsForm(
      input: {
      formId: 1
        fieldValues: [
          {
            id: 1
            value: $name
          }
          {
            id: 2
            emailValues: {
              value: $email
            }
          }
          {
            id: 3
            value: $phone
          }
          {
            id: 4
            value: $message
          }
          {
            id: 5
            value: "true"
            checkboxValues: [
              { 
                inputId: 5.1
                value: $checkbox 
              }
            ]
          }
        ]
        ip: "" # IP address
        saveAsDraft: false # If true, the submission will be saved as a draft entry.
        # Set the following to validate part of a multipage form without saving the submission.
        sourcePage: 1
          targetPage: 0
        }
      ) 
      {
        errors {
          id # The field that failed validation.
          message
      }
      entryId # Will return null if submitting a draft entry
      resumeToken # Will return null if submitting an entry.
      entry {
        # See above section on querying Entries.
        id
        status
      }
    }
  }
`

export const SUBMIT_FORM_CONTACT = gql`
  mutation SubmitFormContact(
    $name: String
    $email: String 
    $phone: String
    $subject: String
    $message: String
  ) {
    submitGravityFormsForm(
      input: {
      formId: 3
        fieldValues: [
          {
            id: 1
            value: $name
          }
          {
            id: 2
            emailValues: {
              value: $email
            }
          }
          {
            id: 7
            value: $phone
          }
          {
            id: 3
            value: $subject
          }
          {
            id: 4
            value: $message
          }
        ]
        ip: "" # IP address
        saveAsDraft: false # If true, the submission will be saved as a draft entry.
        # Set the following to validate part of a multipage form without saving the submission.
        sourcePage: 1
          targetPage: 0
        }
      ) 
      {
        errors {
          id # The field that failed validation.
          message
      }
      entryId # Will return null if submitting a draft entry
      resumeToken # Will return null if submitting an entry.
      entry {
        # See above section on querying Entries.
        id
        status
      }
    }
  }
`

export const SUBMIT_FORM_NEWSLETTER = gql`
  mutation SubmitFormNewsletter(
    $first_name: String
    $last_name: String
    $email: String 
  ) {
    submitGravityFormsForm(
      input: {
      formId: 4
        fieldValues: [
          {
            id: 1
            value: $first_name
          }
          {
            id: 2
            value: $last_name
          }
          {
            id: 3
            emailValues: {
              value: $email
            }
          }
        ]
        ip: "" # IP address
        saveAsDraft: false # If true, the submission will be saved as a draft entry.
        # Set the following to validate part of a multipage form without saving the submission.
        sourcePage: 1
          targetPage: 0
        }
      ) 
      {
        errors {
          id # The field that failed validation.
          message
      }
      entryId # Will return null if submitting a draft entry
      resumeToken # Will return null if submitting an entry.
      entry {
        # See above section on querying Entries.
        id
        status
      }
    }
  }
`

export const SUBMIT_FORM_ROSEN = gql`mutation SubmitFormContact(
  $firstname: String
  $lastname: String
  $email: String 
  $company: String
  $image: Upload
  $description: String
) {
  submitGravityFormsForm(
    input: {
    formId: 5
      fieldValues: [
        {
          id: 1
          value: $firstname
        }
        {
          id: 2
          emailValues: {
            value: $lastname
          }
        }
        {
          id: 3
          value: $email
        }
        {
          id: 4
          value: $company
        }
        {
          id: 5
          value: [$image]
        }
        {
          id: 6
          value: $description
        }
      ]
      ip: "" # IP address
      saveAsDraft: false # If true, the submission will be saved as a draft entry.
      # Set the following to validate part of a multipage form without saving the submission.
      sourcePage: 1
        targetPage: 0
      }
    ) 
    {
      errors {
        id # The field that failed validation.
        message
    }
    entryId # Will return null if submitting a draft entry
    resumeToken # Will return null if submitting an entry.
    entry {
      # See above section on querying Entries.
      id
      status
    }
  }
}`