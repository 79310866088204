import React, { useState, useContext, createContext, useCallback, useEffect } from 'react'

const LanguageContext = createContext({})

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState('pt_BR')

  const toggleLaguage = useCallback((lang) => {
    setLanguage(lang)
  }, [])

  useEffect(() => {
    console.log(language)
  }, [language])

  return (
    <LanguageContext.Provider value={{
      language,
      toggleLaguage
    }}>
      {children}
    </LanguageContext.Provider>
  )
}

export function useLanguage() {
  const context = useContext(LanguageContext)

  return context
}