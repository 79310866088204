import React, { useRef, useState, useEffect } from 'react'
import { useLanguage } from '../../hooks/useLanguage'
// import { Form } from "@unform/web";
// import * as Yup from "yup";

import {
  Container,
  Top,
  Content
} from '../../styles/PicturesRosen';
import Banner from '../../assests/images/call-for-paper.png'
// import { Modal } from '../../components/Modal'
// import checked from "../../assests/images/checked.png"

// import Input from '../../components/Input';
// import InputTextarea from '../../components/InputTextarea';
// import InputFile from '../../components/InputFile';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
import { Fragment } from 'react/cjs/react.production.min';
import EventInfo from '../../components/EventInfo';

function PicturesRosen() {
  const { language } = useLanguage()
  const accordion = useRef(null)
  // const [show, setShow] = useState(false);
  // const [modalTerms, setModalTerms] = useState(false)
  // const closeModalHandler = () => setShow(false);

  // const formRef = useRef(null);
  // const [submitForm, setSubmitForm] = useState(false)

  useEffect(() => {
    const lis = accordion.current?.childNodes

    if (lis) {
      lis.forEach((element) => {
        const currentHeight = element.offsetHeight

        element.setAttribute('data-original-height', currentHeight)

        const title = element.querySelector('h3')
        const titleHeight = title.offsetHeight

        element.style.height = `${titleHeight}px`
        element.classList.remove('active')

        element.addEventListener('click', handleExpandeContent)
      })
    }
  }, [])

  function handleExpandeContent(event) {
    const target = event.currentTarget
    const title = target.querySelector('h3')
    const titleHeight = title.offsetHeight

    if (target.classList.contains('active')) {
      target.style.height = `${titleHeight}px`
      target.classList.remove('active')

      return
    }

    const originalHeight = target.getAttribute('data-original-height')

    target.style.height = `${originalHeight}px`
    target.classList.add('active')
  }

  // async function handleSubmitForm(data) {
  //   try {
  //     const schema = Yup.object().shape({
  //       firstname: Yup.string().required(`${language === "pt_BR" ? "Campo obrigatório" : "Required field"}`),
  //       lastname: Yup.string().required(`${language === "pt_BR" ? "Campo obrigatório" : "Required field"}`),
  //       email: Yup.string()
  //         .email(`${language === "pt_BR" ? "E-mail inválido" : "Invalid e-mail"}`)
  //         .required(`${language === "pt_BR" ? "Campo obrigatório" : "Required field"}`),
  //       company: Yup.string().required(`${language === "pt_BR" ? "Campo obrigatório" : "Required field"}`),
  //       description: Yup.string().required(`${language === "pt_BR" ? "Campo obrigatório" : "Required field"}`),
  //       concordo: Yup.string()
  //         .test(
  //           '',
  //           `${language === "pt_BR" ? "Campo obrigatório" : "Required field"}`,
  //           (valeu) => valeu === "true"
  //         ),
  //       image: Yup.mixed()
  //         .required(`${language === "pt_BR" ? "Selecione uma imagem." : "Select an image."}`),
  //     })

  //     await schema.validate(data, {
  //       abortEarly: false,
  //     });

  //     regristreForm(data)
  //     formRef.current.setErrors({});
  //   } catch (err) {
  //     if (err instanceof Yup.ValidationError) {
  //       const errorMessages = {};

  //       err.inner.forEach((error) => {
  //         errorMessages[error.path] = error.message;
  //       });

  //       formRef.current.setErrors(errorMessages);
  //     }
  //   }
  // }

  // async function regristreForm(data) {
  //   setSubmitForm(true)
  //   const formdata = new FormData();

  //   formdata.append("input_1", data.firstname);
  //   formdata.append("input_2", data.lastname);
  //   formdata.append("input_3", data.email);
  //   formdata.append("input_4", data.company);
  //   formdata.append("input_5", data.image);
  //   formdata.append("input_6", data.description);

  //   axios
  //     .post(
  //       'https://ibprioapi.crpweb.com.br/wp-json/gf/v2/forms/5/submissions',
  //       formdata
  //     )
  //     .then((_) => {
  //       setShow(true)
  //       setSubmitForm(false)
  //     })
  //     .catch(err => {
  //       const mesagem = language === 'pt_BR'
  //         ? "Ocorreu um erro ao enviar o formulário."
  //         : 'An error occured when submitting the form.'

  //       alert(mesagem)
  //       setSubmitForm(false)
  //       console.error(err)
  //     })
  // }

  return (
    <>
      <Container>
        <Top BgImage={Banner} alt="Imagen de dutos">
          <h1>Call for Papers</h1>
        </Top>
        <Content>
        
        <h3 className="main-title">Call for Papers</h3>
          {language === 'en_US'
            ? (<Fragment>
              <div>
                <p>In 2021 we innovated by making a 100% digital edition, but next year we will be back to face-to-face meetings! There were more than 270 technical works presented to more than 1,400 participants and in 2023 you can be part of the program and present your technical work to more than three thousand people. Check out more details and send your work through the link until March 17th.</p>
              </div>
            </Fragment>)
            : (<Fragment>
              <div>
                <p>Em 2021 inovamos fazendo uma edição 100% digital, mas no próximo ano estaremos de volta ao encontro presencial! Foram mais de 270 trabalhos técnicos apresentados para mais de 1.400 participantes e em 2023 você pode fazer parte da programação e apresentar seu trabalho técnico para mais de três mil pessoas. Confira mais detalhes e envie seu trabalho pelo link até o dia 17 de março.</p>
              </div>
            </Fragment>)
          }

          <h2>TEMÁRIO</h2>

          <ul className="accordion" ref={accordion}>
            {/* <li>
              {language === 'en_US'
                ? (<Fragment>
                  <h3>Call for Pictures Rules <svg xmlns="http://www.w3.org/2000/svg" width="28.536" height="17.096" viewBox="0 0 28.536 17.096"><path d="M1200.575,396l12.854,12.854,3.749-3.749,9.1-9.1" transform="translate(-1199.161 -394.586)" fill="none" stroke="#747474" strokeWidth="4" /></svg></h3>
                  <div>
                    <p><strong>Registrations:</strong> Fill out the registration form on the Rio Pipeline Expo & Conference website to submit your photo.</p>
                    <p><strong>Deadline:</strong> The call for pictures ends on December 10th, 2021.</p>
                    <p><strong>File size:</strong> Acceptable file size is between 1MB and 10MB.</p>
                    <p><strong>Format:</strong> Pictures must be submitted in one of the generally recognized digital image formats (.jpg, .tif or .png). For printing purposes, the pictures require a minimum resolution of 300 dpi. The picture must not contain embedded information. Pictures will not be returned to participants.</p>
                    <p><strong>Quality:</strong> Photos should be high-quality original files, preferably taken with a professional camera or high-quality smartphone.</p>
                    <p><strong>Information:</strong> Along with the picture, participants must provide a description of the submitted picture. Each description must be written in English and must not exceed 200 words. ROSEN and the Brazilian Institute of Petroleum and Gas (IBP), reserve the right to edit any written description submitted with a picture. Final author approval will be sought for any edited descriptions.</p>
                    <p><strong>Copyright and Permissions:</strong> Entrants are responsible for obtaining any clearances or any other permissions or licenses necessary to submit their entry. The full copyright title remains with the author/owner of any entry. ROSEN and the Brazilian Institute of Petroleum and Gas (IBP) will have the right to display, reproduce and distribute in any way, whether commercial or not, one or all of the selected entries.</p>
                    <p><strong>Screening:</strong> A panel of professionals from the pipeline community will anonymously judge the quality and relevance of entries received. The judges' decisions will be final.</p>
                    <p><strong>Recognition:</strong> Selected pictures and associated text will be included in the next edition of the ROSEN’s Encyclopedia of Pipeline Defects. The names of the authors will be recognized in this new publication.</p>
                  </div>
                </Fragment>)
                : (<Fragment>
                  <h3>Regras do Call for Pictures <svg xmlns="http://www.w3.org/2000/svg" width="28.536" height="17.096" viewBox="0 0 28.536 17.096"><path d="M1200.575,396l12.854,12.854,3.749-3.749,9.1-9.1" transform="translate(-1199.161 -394.586)" fill="none" stroke="#747474" strokeWidth="4" /></svg></h3>
                  <div>
                    <p><strong>Inscrições:</strong> Preencha o formulário de inscrição no site da Rio Pipeline Expo & Conference para enviar sua foto.</p>
                    <p><strong>Prazo:</strong> As inscrições devem ser enviadas até a meia-noite de 10 de dezembro de 2021. Inscrições atrasadas, incompletas ou inadequadas serão consideradas inválidas.</p>
                    <p><strong>Tamanho do arquivo:</strong> O tamanho de arquivo aceitável é entre 1 MB e 10 MB.</p>
                    <p><strong>Formato:</strong> As imagens devem ser enviadas em um dos formatos de imagem geralmente reconhecidos (.jpg, .tif ou .png). Para fins de impressão, as imagens vencedoras requerem uma resolução mínima de 300 dpi. A imagem não deve conter informações incorporadas. As imagens não serão devolvidas aos participantes.</p>
                    <p><strong>Qualidade:</strong> As fotos devem ser arquivos originais de alta qualidade, de preferência tiradas com câmera profissional ou smartphone de alta qualidade.</p>
                    <p><strong>Informações:</strong> Junto com a imagem, os participantes devem fornecer uma descrição da imagem enviada. Cada descrição deve ser escrita em inglês e não deve exceder 200 palavras. A ROSEN e o Instituto Brasileiro de Petróleo e Gás - IBP, reserva-se o direito de editar qualquer descrição escrita submetida com imagem. A aprovação final do autor será solicitada para qualquer descrição editada.</p>
                    <p><strong>Direitos autorais e permissões:</strong> Os participantes são responsáveis por obter quaisquer liberações ou qualquer outra permissão ou licença necessária para o envio de seu trabalho para esta competição e publicação futura. O título completo dos direitos autorais permanece com o autor / proprietário de qualquer entrada. A ROSEN e o Instituto Brasileiro do Petróleo e Gás - IBP terão o direito de exibir, reproduzir e distribuir de qualquer forma, seja ela comercial ou não, uma ou todas as inscrições selecionadas.</p>
                    <p><strong>Triagem:</strong> Um painel de profissionais da comunidade de pipeline julgará anonimamente a qualidade e a relevância das inscrições recebidas. As decisões dos juízes serão finais.</p>
                    <p><strong>Reconhecimento:</strong> As imagens selecionadas e o texto associado serão incluídos na próxima edição da Enciclopédia de Defeitos de Pipeline da ROSEN. Os nomes dos autores serão reconhecidos nesta nova publicação.</p>
                  </div>
                </Fragment>)
              }
            </li>

            <li>
              {language === 'en_US'
                ? (<Fragment>
                  <h3>Terms and Conditions <svg xmlns="http://www.w3.org/2000/svg" width="28.536" height="17.096" viewBox="0 0 28.536 17.096"><path d="M1200.575,396l12.854,12.854,3.749-3.749,9.1-9.1" transform="translate(-1199.161 -394.586)" fill="none" stroke="#747474" strokeWidth="4" /></svg></h3>
                  <div>
                    <p>1. ROSEN Swiss AG (RSA) is a company incorporated in Switzerland with its headquarters at Obere Spichermatt 14, 6370 Stans.</p>
                    <p>2. By submitting an entry, you agree to be legally bound by the Call for Pictures Rules and these Terms and Conditions.</p>
                    <p>3. All personal data submitted will be kept securely and in accordance with the GDPR Data Protection Act 1998.</p>
                  </div>
                </Fragment>)
                : (<Fragment>
                  <h3>Termos e Condições do Call for Pictures <svg xmlns="http://www.w3.org/2000/svg" width="28.536" height="17.096" viewBox="0 0 28.536 17.096"><path d="M1200.575,396l12.854,12.854,3.749-3.749,9.1-9.1" transform="translate(-1199.161 -394.586)" fill="none" stroke="#747474" strokeWidth="4" /></svg></h3>
                  <div>
                    <p>1. ROSEN Swiss AG (RSA), é uma empresa constituída na Suíça com sede em Obere Spichermatt 14, 6370 Stans.</p>
                    <p>2. Ao enviar uma inscrição, você concorda em estar legalmente vinculado às Regras da Competição e a estes Termos e Condições.</p>
                    <p>3. Todos os dados pessoais enviados serão mantidos com segurança e de acordo com a Lei de Proteção de Dados GDPR de 1998.</p>
                  </div>
                </Fragment>)
              }
            </li> */}
            
            <li>
                  <h3>Technical Tracks <svg xmlns="http://www.w3.org/2000/svg" width="28.536" height="17.096" viewBox="0 0 28.536 17.096"><path d="M1200.575,396l12.854,12.854,3.749-3.749,9.1-9.1" transform="translate(-1199.161 -394.586)" fill="none" stroke="#747474" strokeWidth="4" /></svg></h3>
                  <div>
                    <p><strong>1. Control Room & AUTOMATION:</strong> Cyber Security. Control Room Management. Supervisory Systems. Measurement and Metering. Sensoring Technologies. Automation and Instrumentation. Leak Detection.</p>
                    <p><strong>2. Subsea Systems:</strong> Rigid pipelines and risers. Flexibles and umbilicals. Ultra deep-water challenges. Flow Assurance. Sensoring technologies and monitoring systems. Design. Installation. Pre-commissioning. Decommissioning. Welding processes. CFD. Fracture, fatigue, collapse. VIV.  On bottom stability and pipe soil interaction. Operational experiences and lessons learned. Submarine drones.</p>
                    <p><strong>3. Logistics and Operation:</strong> Operational Excellence. Optimization of Pipeline Systems and Networks. Capacity expansion and cost reductions studies. Operation Management Systems and Technologies. Hydraulic Simulations. De-bottlenecking. Reduction of interfaces in multi-products pipelines. Management Systems and Technologies for Pipeline Operation. Certification. Multi-modal transport. Ship demurring.</p>
                    <p><strong>4. Risk & Reliability:</strong> Reliability Engineering. Risk Based Inspection. Reliability Centered Maintenance. Human Reliability. Crisis and Risk Management. Supply Chain Optimization. Contingency Plans.</p>
                    <p><strong>5. Structural integrity:</strong> PIMS (Pipeline Integrity Management Systems). Conditional State Prediction. Defect Assessment. Failure Modes and Mechanisms. Pipeline Ageing. Hydrotesting. MOP validation. Life-extensions and re-purposings. Failure Analysis.</p>
                    <p><strong>6. Corrosion & Cracking:</strong> SCC. Environmentally Assisted Cracking. Crack Threat Assessment. Corrosion Prevention. Corrosion Inhibitors. Corrosion Monitoring. Microbiological Corrosion.  Erosion. Corrosion under insulation.</p>
                    <p><strong>7. Inspection Technologies:</strong> ILI tools. Inspection of unpiggabble lines . Technologies for in situ, non destructive testing. Detection of coating defects. Detection of cracks, metal loss, geometric, manufacture and/or welding defects. Inertial Inspections. Material Inspections. Circumferential defects. Data Validation.</p>
                    <p><strong>8. Design, Construction, Materials and Right of way creation:</strong> Route engineering. Pipes and accessories specification. Pumps and compressors specification. Crossings. Horizontal drilling and micro tunneling technologies. Construction and fabrication equipment. New construction procedures. New metallic and non-metallic materials. Thermo hydraulic simulations.</p>
                    <p><strong>9. GIS Mapping and  Geotechnical analyses:</strong> Geographic information systems. Pipeline and right of way mapping. Pipeline location and geopositioning. Satellite imagining. Geomatics. Geotechnical and hydrotechnical inspections,  monitoring, assessments and interventions. Landslides and mass movement. Geotechnical sensoring.  Erosion and upheaval displacement. Righ of way inspections. Aerial drones.</p>
                    <p><strong>10. Maintenance & Rehabilitation of Pipeline systems:</strong> Maintenance management technologies. Maintenance planning. Reliability centered maintenance. Hot tapping. Qualification of piepeline repair services and materials. Special procedures.</p>
                    <p><strong>11. Distribution, Storage & Terminals:</strong> Equipment selection and maintenance best practices. Performance Analysis. Proccess Optimization. Custody Transference.. Energy Efficiency and Emissions Control</p>
                  </div>
            </li>
            <li>
                  <h3>Strategic Tracks <svg xmlns="http://www.w3.org/2000/svg" width="28.536" height="17.096" viewBox="0 0 28.536 17.096"><path d="M1200.575,396l12.854,12.854,3.749-3.749,9.1-9.1" transform="translate(-1199.161 -394.586)" fill="none" stroke="#747474" strokeWidth="4" /></svg></h3>
                  <div>
                    <p><strong>1.ESG. Environmental License:</strong> Environmental Management. Safety Management. Impact mitigation. Compensatory measures. Public Perception Management. Engagement with right away communities. Governance indicators. Data Governance on Pipeline Operations.</p>
                    <p><strong>2.Business Environment:</strong> Energy Security. LNG Market. Regulation. Public Perception. Investor confidence. Independent Operators. Commodity Pricing. Regional and International Market Outlooks. Geopolitical and Trading shifts.</p>
                    <p><strong>3.Energy Transition:</strong> Energy Transition Outlooks. Hydrogen Master Planning. Strategies and technologies for decarbonization. Projects, resources and knowledge sharing on ethanol, ammonia, hydrogen and CO2 pipelines.</p>
                    <p><strong>4.Human Capital:</strong> Talent Acquisition. Competence Development Programs. Competence Diagnostic. Distance learning programs. R&D workforces. Technical associations supporting initiatives/programs.</p>
                  </div>
            </li>
          </ul>

          <EventInfo id="next-section" papers={true}/>
        </Content>

        {/* <Content>
          <Form
            ref={formRef}
            className="subscription"
            onSubmit={handleSubmitForm}
          >
            <h2>{`${language === "pt_BR" ? "Envie sua inscrição" : "Submit your application"}`}</h2>

            <Input
              label={`${language === "pt_BR" ? "Nome *" : "Name *"}`}
              name="firstname"
            />

            <Input
              label={`${language === "pt_BR" ? "Sobrenome *" : "Lastname *"}`}
              name="lastname"
            />

            <Input
              label={`${language === "pt_BR" ? "Endereço de e-mail *" : "E-mail address *"}`}
              name="email"
            />

            <Input
              label={`${language === "pt_BR" ? "Empresa *" : "Company *"}`}
              name="company"
            />

            <InputFile
              label={`${language === "pt_BR" ? "Adicionar imagem *" : "Add image *"}`}
              name="image"
              accept="image/png, image/jpeg"
              size="2097152"
              onInput={e => console.log(e.current)}
            />

            <InputTextarea
              label={`${language === "pt_BR" ? "Por favor, descreva a sua imagem, em inglês (máximo de 200 palavras) *" : "Please describe your image in English (maximum 200 words) *"}`}
              name="description"
              maxlength="200"
            />

            <Input
              customClass="input-checkbox"
              type="checkbox"
              name="concordo"
              label={`${language === "pt_BR" ? "Ao assinalar esta caixa, eu concordo com os" : "I agree to the terms of privacy"}`}
              onclick={() => setModalTerms(true)}
              textClick={`${language === "pt_BR" ? "termos de privacidade." : "terms of privacy."}`}
            />

            <button
              name="submit"
              type="submit"
              className="contact-submit"
              disabled={submitForm}
            >
              {`${language === "pt_BR" ? submitForm ? 'Enviando...' : 'Enviar' : submitForm ? 'Sending...' : 'Send'}`}
            </button>
          </Form>
        </Content>
        <Modal show={show} close={closeModalHandler}>
          <img src={checked} alt="checked" />
          {language === 'en_US'
            ? (<Fragment>
              <h4>Thanks!</h4>
              <strong>Your application has been successfully submitted!</strong>
              <p>Stay tuned to learn more about the contest.</p>
              <Link to='/schedule'>CHECK OUT THE EVENT SCHEDULE</Link>
            </Fragment>)
            : (<Fragment>
              <h4>Obrigado!</h4>
              <strong>Sua inscrição foi submetida com sucesso!</strong>
              <p>Fique ligado para saber mais sobre o concurso.</p>
              <Link to='/programacao'>CONFIRA A PROGRAMAÇÃO DO EVENTO</Link>
            </Fragment>)
          }
        </Modal>
        <Modal show={modalTerms} close={() => setModalTerms(false)}>
          {language === 'en_US'
            ? (<Fragment>
              <h2>CONSENT FORM</h2>
              <div>
                <p>I have read and understand the rules for the “Call for Pictures”. I hereby grant ROSEN Swiss AG (RSA) and the Brazilian Petroleum and Gas Institute – IBP and its designee or assignees, my consent to use, in whole or in part, the images submitted by me. The photographs are presented for the purpose of being judged and in case of being selected will be included in the next edition of the ROSEN´s Encyclopedia of Pipeline Defects, they may be reproduced, transfer to third parties, distributed, publicly displayed and/or modified either in print or electronically. Concerning the use of these images, I hereby waive any right that I may have to monetary compensation, or the advertising or other uses made of the picture. I also indemnify the Brazilian Petroleum and Gas Institute – IBP and RSA and its designees or assignees from any and all liability that may or could arise from the taking and use of these images.</p>

                <p>I agree that:</p>

                <p>1. I am responsible for obtaining any releases or other permissions or licenses necessary to submit images for and future publication. Full copyright title remains with the author/owner of the entry.</p>

                <p>2. Any entry must not infringe the rights of third parties including copyright, trade mark, trade secrets, privacy, publicity, personal or proprietary rights.</p>

                <p>3. By submitting an entry, I agree to grant the Brazilian Petroleum and Gas Institute – IBP and RSA a perpetual, royalty-free, non-exclusive, sub-licensable right and worldwide license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, display and exercise all publicity rights with respect to your entry, and/or to incorporate the entry in other works, in any media now known or later developed for the full term of any rights that may exist in the entry.</p>

                <p>4. I am aware that I may be required to participate in publicity, which may include the publication of their name and photograph in any media. I agree that my personal data may be used for this purpose.</p>

                <p>5. I am aware of the Brazilian Petroleum and Gas Institute – IBP and RSA’s Data Protection Policy and have read Data Protection terms.</p>

                <p>6. I consent to the Brazilian Petroleum and Gas Institute – IBP and RSA using my personal data for the purposes described in this Form and understand that I can withdraw my consent at any time.</p>

                <p>7. I am aware that I may contact the Brazilian Petroleum and Gas Institute – IBP at <a href="mailto:eventos@ibp.org.br">eventos@ibp.org.br</a> and RSA at <a href="cdpo@rosen-group.com">cdpo@rosen-group.com</a>, at any time regarding the management of my personal data for more information in this respect.</p>

                <p>8. I would prefer to hear from Brazilian Petroleum and Gas Institute – IBP and RSA through any of the following channels: SMS, E-mail, Social media.</p>

                <p>Yes, I have read and Agree to the rules laid out in this Form.</p>
              </div>
            </Fragment>)
            : (<Fragment>
              <h2>FORMULÁRIO DE CONSENTIMENTO</h2>
              <div>
                <p>Eu li e entendi as regras do “Call for Pictures”. Concedo à ROSEN Swiss AG (RSA) e ao Instituto Brasileiro de Petróleo e Gás - IBP e sua pessoa designada ou cessionários, meu consentimento para a utilização, total ou parcial, das imagens por mim enviadas. As fotografias são apresentadas para efeito de julgamento e em caso de seleção serão incluídas na próxima edição da Encyclopedia of Pipeline Defects da ROSEN, podendo ser reproduzidas, transferidas a terceiros, distribuídas, expostas publicamente e / ou modificado na impressão ou eletronicamente. No que diz respeito ao uso dessas imagens, eu renuncio a qualquer direito que eu possa ter a compensação monetária, ou a publicidade ou outros usos feitos da imagem. Também indenizo o Instituto Brasileiro de Petróleo e Gás - IBP e RSA e seus designados ou cessionários de toda e qualquer responsabilidade que possa surgir da obtenção e uso dessas imagens.</p>

                <p>Eu concordo que: </p>

                <p>1. Sou responsável por obter quaisquer liberações ou outras permissões ou licenças necessárias para enviar imagens para o “Call for Pictures” e publicação futura. O título completo dos direitos autorais permanece com o autor / proprietário da entrada.</p>

                <p>2. Qualquer inscrição não deve infringir os direitos de terceiros, incluindo direitos autorais, marca comercial, segredos comerciais, privacidade, publicidade, direitos pessoais ou de propriedade.</p>

                <p>3. Ao enviar uma inscrição, concordo em conceder ao Instituto Brasileiro de Petróleo e Gás - IBP e RSA um direito perpétuo, isento de royalties, não exclusivo, sublicenciável e licença mundial para usar, reproduzir, modificar, adaptar , publicar, traduzir, criar trabalhos derivados, distribuir, executar, exibir e exercer todos os direitos de publicidade com relação à sua inscrição e / ou incorporar a inscrição em outras obras, em qualquer mídia agora conhecida ou desenvolvida posteriormente durante todo o período de quaisquer direitos que possam existir na entrada.</p>

                <p>4. Estou ciente de que o vencedor poderá ser obrigado a participar de publicidade relacionada ao concurso, que poderá incluir a publicação de seu nome e fotografia em qualquer meio de comunicação. Eu concordo que meus dados pessoais podem ser usados para este propósito.</p>

                <p>5. Estou ciente do Instituto Brasileiro de Petróleo e Gás - IBP e da Política de Proteção de Dados da RSA e li os termos de Proteção de Dados.</p>

                <p>6. Consinto que o Instituto Brasileiro de Petróleo e Gás - IBP e RSA utilize meus dados pessoais para os fins descritos neste Formulário e entendo que posso retirar meu consentimento a qualquer momento.</p>

                <p>7. Estou ciente de que posso entrar em contato com o Instituto Brasileiro de Petróleo e Gás - IBP em <a href="mailto:eventos@ibp.org.br">eventos@ibp.org.br</a> e RSA em <a href="mailto:cdpo@rosen-group.com">cdpo@rosen-group.com</a>, a qualquer momento, a respeito do gerenciamento de meus dados pessoais para mais informações em Esse respeito.</p>

                <p>8. Prefiro receber notícias do Instituto Brasileiro de Petróleo e Gás - IBP e RSA por qualquer um dos seguintes canais: SMS, E-mail, Mídias sociais.</p>

                <p>Sim, li e concordo com as regras estabelecidas neste formulário.</p>
              </div>
            </Fragment>)}
        </Modal> */}
        
      </Container>

    </>
  )
}

export default PicturesRosen;
