import React, { useState, useContext, createContext, useCallback } from 'react'

const SideBarContext = createContext({})

export function SideBarProvider({ children }) {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = useCallback(() => {
    setSidebar((prevState) => !prevState);
  }, [])

  return (
    <SideBarContext.Provider value={{
      sidebar,
      showSidebar
    }}>
      {children}
    </SideBarContext.Provider>
  )
}

export function useSideBar() {
  const context = useContext(SideBarContext)

  return context
}