import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container } from './styles'
import { IconContext } from 'react-icons';

import imgBR from '../../assests/Imagens_IBP/Home/pt-br.png'
import imgEn from '../../assests/Imagens_IBP/Home/en.png'

import FormTop from '../FormTop'
import { useLanguage } from '../../hooks/useLanguage';
import { useSideBar } from '../../hooks/useSidebar';


function Topbar() {
  let history = useHistory();
  const { toggleLaguage } = useLanguage()
  const [searchText, setSearchText] = useState('')
  const [toggleSearch, setToggleSearch] = useState(false)
  const { language } = useLanguage()
  const { sidebar, showSidebar } = useSideBar()

  useEffect(() => {
    const body = document.querySelector('body')

    if (sidebar) {
      if (body) {
        body.style.overflow = 'hidden'
        body.style.height = '100vh'
      }
    } else if (body) {
      body.style.overflow = ''
      body.style.height = ''
    }
  }, [sidebar])

  function handleSubmitSearch(event) {
    event.preventDefault();

    if (!searchText) {
      setToggleSearch(!toggleSearch)
    } else {
      if (language === 'pt_BR') {
        history.push(`/busca?q=${searchText}`);
      } else {
        history.push(`/search?q=${searchText}`)
      }
      setToggleSearch(!toggleSearch)
      setSearchText('')
    }
  }

  return (
    <Container>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='topbar'>
          <ul className='topbar-list'>
            <li className='topbar-list__item'>
              <form
                onSubmit={handleSubmitSearch}
                action="/busca"
                method="get"
                className={`${toggleSearch ? "form-search active" : 'form-search'}`}
              >
                <input
                  type="text"
                  placeholder={language === 'en_US' ? "What are you search?" : "O que você procura?"}
                  name="q" value={searchText}
                  onInput={event => setSearchText(event.target.value)}
                />
                <button type="submit">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.004" viewBox="0 0 16 16.004"><path d="M20.312,19.339l-4.45-4.492a6.342,6.342,0,1,0-.962.975l4.421,4.462a.685.685,0,0,0,.967.025A.689.689,0,0,0,20.312,19.339Zm-9.433-3.462a5.007,5.007,0,1,1,3.542-1.467A4.977,4.977,0,0,1,10.879,15.876Z" transform="translate(-4.5 -4.493)" /></svg>
                </button>
              </form>
            </li>
            <li className='topbar-list__item'>
              <Link to='faq' className='title'>
                <span className="item__list">
                  {language === 'en_US'
                    ? 'FAQ'
                    : 'FAQ'}
                </span>
              </Link>
            </li>
            <li className='topbar-list__item topbar-list__item--img fale-conosco'>
              <Link to='#' className='title'>
                <span className="item__list" onClick={showSidebar}>
                  {language === 'en_US'
                    ? 'Contact us'
                    : ' Fale Conosco'}
                </span>
              </Link>
            </li>
            <li className='topbar-list__item topbar-list__item--img'>
              <span onClick={() => toggleLaguage("pt_BR")}>
                <img src={imgBR} alt="bandeira Brasil" />
              </span>

              <span onClick={() => toggleLaguage("en_US")}>
                <img src={imgEn} alt="bandeira EUA" />
              </span>
            </li>
          </ul>
        </div>

        <div className={sidebar ? 'topbar-form active' : 'topbar-form'}>
          <FormTop onclick={showSidebar} />
        </div>

      </IconContext.Provider>
    </Container>
  );
}

export default Topbar;