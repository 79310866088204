import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
 
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    color: #000;
    margin: 0;
    margin-right: 10px;
  } 

  .social-media-share {
    position: relative;

    &--invert {
      .shares {
        button {
          span {
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }

    .mobile-share {
      display: none;
      background: none;
      border: none;

      &--invert {             
        & > svg {
          g {
            path {
              stroke: #fff;
            }
          }            
        }         
      }
    }

    .shares {
      display: grid;
      grid-template-columns: repeat(3, 20px);
      column-gap: 5px;
      align-items: center;
      justify-items: center;

      button {
        transform: rotate(-90deg);
      }
    }    
  }

  @media (max-width: 990px) {
    .social-media-share {
      &--invert {
        .shares {
          button {
            span {
              svg {
                path {
                  fill: var(--orange);
                  display: none;
                }
              }
            }
          }
        }
      }

      .mobile-share {
        display: block;
      }

      .shares {
        display: none;


        &.show {
          z-index: 999999;
          display: grid;
          position: absolute;
          top: 140%;
          transform: translateX(-50%);
          left: 50%;
          grid-template-columns: repeat(1,20px);
          row-gap: 10px;
          background-color: #fff;
          padding: 5px;

          button {
            span {
              svg {
                path {
                  fill: var(--orange);
                  display: block;
                }
              }
            }
          }
        }        
      }
    }
  }
`