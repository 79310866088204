import React from "react";
import { ContainerPlayer } from "./styles";
import { useLanguage } from "../../hooks/useLanguage";

const Player = ({ children }) => {
  const { language } = useLanguage();
  return (
    <ContainerPlayer>
      {children}
      <div className="welcome__video">
        <iframe
          src="https://www.youtube.com/embed/j5PJp7gbkII?si=E3Q7UM6etRNnKqNa"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="welcome__content">
        <h2 className="subtitle">
          {language === "en_US"
            ? "The meeting place for the international pipeline community"
            : "O ponto de encontro da comunidade internacional de dutos"}
        </h2>
        {language === "en_US" ? (
          <p>
            Rio Pipeline brings together the most diverse participants in this
            segment, permeated by highly relevant technical content, networking
            and a business environment. With the participation of <strong>specialists</strong>
            from all over the world, the event provides a qualified discussion
            on improving efficiency, quality and safety in pipeline transport.
            <strong>Following the great success of the 2023 edition, we have announced
            the date of the next big meeting: September 9-11, 2025!</strong> See you
            there!
          </p>
        ) : (
          <p>
            A <strong>Rio Pipeline</strong> é o encontro entre os mais diversos
            participantes desse segmento, permeado por conteúdo técnico de alta
            relevância, networking e ambiente de negócios. Com a participação
            de <strong>especialistas</strong> de todo o mundo, o evento propicia
            um debate qualificado sobre melhoria da eficiência, qualidade e
            segurança no transporte por dutos.{" "}
            <strong>
              Após o grande sucesso da edição de 2023, anunciamos a data desse
              próximo grande encontro: de 09 à 11 de setembro de 2025!
            </strong>{" "}
            Nos vemos lá!
          </p>
        )}
      </div>
    </ContainerPlayer>
  );
};

export default Player;
