import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 1366px;
  padding: 0 2rem;
  margin: 2rem auto;

  h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 2.25rem;
    font-weight: 700;
    margin: 0;

    span {
      font-weight: 500;
      font-style: italic;
    }
  }

  .header {
    margin: 1rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E9E9E9;

    p {
      font-family: 'Open Sans', sans-serif;
      font-size: 1rem;
      margin: 0;
      margin-bottom: 0.875rem;
    }

    form {
      label {
        position: relative;
        width: 440px;
        max-width: 100%;
        height: 40px;
        border: 1px solid #707070;

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding-left: 15px;
          border: none;
        }

        button {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          z-index: 1;

          background: none;
          border: none;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
      
    }
  }

  .posts-list {
    list-style: none;
    padding: 0;
    margin: 0;

    .post {
      margin: 2rem 0;

      & + .post {
        padding-top: 2rem;
        border-top: 1px solid #E9E9E9;
      }

      div {
        text-decoration: none;
        color: #434343;

        header {
          display: flex;
          flex-direction: column-reverse;

          a {
            h2 {
              font-family: 'Open Sans', sans-serif;
              font-size: 1.125rem;
              font-weight: 600;
              color: var(--orange);
              margin: 0.625rem 0;
            }

            h3 {
              font-family: 'Open Sans', sans-serif;
              font-size: 0.875rem;
              font-weight: 700;
              color: #6F8080;
              margin: 0;
            }
          }   
        }

        a {
          font-family: 'Open Sans', sans-serif;
          font-size: 0.875rem;
          color: #434343;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }

  .text-message {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem;
    color: #000;
    margin: 2rem 0;
  }

  .loading-post {
    background: var(--orange);
    padding: 10px 60px;
    border: none;

    font-family: 'Open Sans', sans-serif;
    font-size: 0.75rem;
    font-weight: 700;
    color: #000000;

    &:disabled {
      opacity: .8;
    }
  }
`