import { useEffect, useState } from "react"

export default function StaffCard(staff) {
    const [member, setMember] = useState()

    useEffect(() => {
        setMember(staff.staff)
    }, [])

    if(member) {
        return(
            <div className="staff-member">
                <img src={member.image} />
                <div className="staff-member__content">
                    <div className="staff-member__text">
                        <p className="name">
                            {member.name}
                        </p>
                        <p className="company">
                            {member.company}
                        </p>
                    </div>
                    <a href={member.url} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                            <path d="M31.694,2H4.426A2.327,2.327,0,0,0,2.06,4.288V31.712A2.327,2.327,0,0,0,4.426,34H31.694a2.327,2.327,0,0,0,2.366-2.288V4.288A2.327,2.327,0,0,0,31.694,2ZM11.766,28.784H6.937v-14.4h4.829ZM9.352,12.368h0a2.5,2.5,0,1,1,0-4.992,2.532,2.532,0,0,1,2.424,1.159,2.5,2.5,0,0,1,0,2.674A2.532,2.532,0,0,1,9.352,12.368ZM29.183,28.784H24.354V21.056c0-1.936-.692-3.2-2.447-3.2A2.655,2.655,0,0,0,19.428,19.6a3.182,3.182,0,0,0-.161,1.168v8H14.438v-14.4h4.829V16.4A4.835,4.835,0,0,1,23.629,14c3.219,0,5.553,2.064,5.553,6.5Z" transform="translate(-2.06 -2)"/>
                        </svg>
                    </a>
                </div>
            </div>
        )
    }

    return null
}