import styled from 'styled-components';

export const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  position: relative;
  height: auto;
  font-family: "Open sans", sans-serif;

  max-width: 1336px;
  width: auto;

  margin: 40px auto;
    padding: 0 1rem;
    line-height: 4;


  span {
  margin-left: 0.5rem;
  }

  
`;

export const Container = styled.div`
  

 

`;

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: auto;
  max-width: 952px;
  width: auto;
  background: #ffffff;
  color: #000000;
  cursor: pointer;

  h2 {
    margin-left: -1rem;
    font-size: 18px;
    font-family: "Open sans", sans-serif;
    font-weight: 600;
  }

  strong {
    color: var(--orange);
    font-weight: bold;
  }
`;

export const Dropdown = styled.div`
  background: #ffff;
  color: #6F8080;
  font-family: "Open sans", sans-serif;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-bottom: 2px solid #CCC;
  max-width: 948px;


 
  p {
    font-size: 1rem;
    color: #000000;
    text-align: start;
    font-weight: 200;
    line-height: 2;
  }


  @media(max-width: 480px) {
    p {
      font-size: 1.3376rem;
    }
  }
`;
