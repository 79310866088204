import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';

import { AccordionSection, Container, Wrap, Dropdown } from './styles'

import { client } from '../../Services/wordpress'
import { removerAcentos } from '../../utils/removeChars'
import { GET_FAQ } from '../../queries/pages'
import { useLanguage } from '../../hooks/useLanguage'

const Accordion = () => {
  const [clicked, setClicked] = useState(false);
  const { language } = useLanguage()
  const [faq, setFaq] = useState([])

  useEffect(() => {
    getDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]) 

  async function getDate() {
    try {
      const res = await client.query({
        query: GET_FAQ,
        variables: { language }
      })

      const { data, errors } = res
      if (errors) {
        console.error("request falhed")
        return
      }

      const newData = data.faqs.nodes.map(
        faq => {
          return {
            id: faq.id,
            title: faq.title,
            content: faq.content,
            class: removerAcentos(faq.title.toLowerCase().replaceAll(" ", "-")),
          }
        }
      )

      setFaq(newData)
    } catch (error) {
      console.error("request fail")
      // console.error(error) // TODO Habilitar somente em degub
    }
  }


  const toggle = index => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };
console.log(faq)
  return (
    <IconContext.Provider value={{ color: '#6F8080', size: '25px' }}>
      <AccordionSection>
        <Container>
          {faq
            .filter(faq => faq.translations !== language)
            .map((faqs, index) => {
              return (
                <>
                  <Wrap onClick={() => toggle(index)} key={index}>
                    <h2><strong>{index + 1}. </strong>{faqs.title}</h2>
                    <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                  </Wrap>
                  {clicked === index ? (
                    <Dropdown dangerouslySetInnerHTML={{ __html: faqs.content }} >
                    </Dropdown>
                  ) : null}
                </>
              )
            })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>
  );
};

export default Accordion;
