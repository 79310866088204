// Page Commitê Organizador
import React, { Fragment } from 'react'
import Breadcump from '../../components/Breadcump'
import { useLanguage } from '../../hooks/useLanguage'
import { Container } from '../../styles/PageCommite'
import { Helmet } from "react-helmet"
import { Top } from '../Expositors/styles'
import { CommitteeStaff } from '../../components/CommitteStaff'

import committe from './data/staff.json'
import Banner from '../../assests/images/Banner-Organizador_Desktop.jpg'

function StrategicCommittee() {
  const { language } = useLanguage()

  return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? 'Strategic Committe - Rio Pipeline'
            : 'Comitê Estratégico 2023 - Rio Pipeline'
          }
        </title>

        {language === 'en_US'
          ? <link rel="canonical" href="/strategic-committee" />
          : <link rel="canonical" href="/comite-estrategico" />
        }
      </Helmet>
      <Top BgImage={Banner} alt="Comitê Estratégico 2023">
        <h1>{language == 'en_US' ? '2023 Strategic Committee' : 'Comitê Estratégico 2023'}</h1>
      </Top>
      <Breadcump customPath={
        language === 'en_US'
          ? [{ link: 'strategic-committee', name: '2023 Strategic Committee' }]
          : [{ link: 'comite-estrategico', name: 'Comitê Estratégico 2023' }]
      } />
      <Container>
        <CommitteeStaff data={committe} />
      </Container>
    </Fragment>
  )
}

export default StrategicCommittee
