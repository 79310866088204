import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useLanguage } from '../../hooks/useLanguage'
import { Bread } from './styles'

const routes_en_US = [
  'sign-up',
  'the-event',
  'schedule',
  'organizing-committee',
  'technical-committee',
  'strategic-committee',
  'international-committee',
  'sponsors',
  'news',
  'category',
  'search',
  'exhibitors',
  'know-the-city',
  'map',
  'partners-event'
]

export default function Breadcump({ customPath = [] }) {
  const { language, toggleLaguage } = useLanguage()
  const { pathname } = useLocation()
  const [paths, setPaths] = useState([])

  useEffect(() => {
    if (pathname) {
      const newPath = pathname.split("/")
        .filter(path => !!path)
        .map(path => ({ link: path, name: path.toLocaleLowerCase().replaceAll(" . ", " ") }))

      setPaths(newPath)

      try {
        const currentPath = routes_en_US.map(route => {
          const paths = newPath.filter(path => path.name === route)
          return paths
        }).flat(Infinity).length > 0

        if (currentPath) {
          toggleLaguage("en_US")
        } else {
          toggleLaguage("pt_BR")
        }
      } catch (error) {
        console.error(error)
        toggleLaguage("pt_BR")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <Bread>
      <li>
        <Link to="/">
          {language === 'en_US'
            ? 'Home'
            : 'Início'}
        </Link>
      </li>
      {customPath.length > 0 ? (
        customPath.map((path, index) => (
          <li key={index.toString()}>
            <Link to={`/${path.link}`}>
              {path.name.replaceAll("-", " ")}
            </Link>
          </li>
        ))
      ) : (
        paths.map((path, index) => (
          <li key={index.toString()}>
            <Link to={`/${path.link}`}>
              {path.name !== "inscreva-se"
                ? path.name.replaceAll("-", " ")
                : path.name
              }
            </Link>
          </li>
        ))
      )}
    </Bread>
  )
}