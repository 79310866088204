// Page Commitê Organizador
// Page de Patrocinadores
import React, { Fragment } from 'react'
import BannerForm from '../../components/BannerForm'
import Breadcump from '../../components/Breadcump'
import GridSponsors from '../../components/GridSponsors'
import { Helmet } from "react-helmet";
import { Container } from '../../styles/PageSponsors'
import { useLanguage } from '../../hooks/useLanguage';

function Sponsors() {
  const { language } = useLanguage()

  return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? 'Sponsors - Rio Pipeline'
            : 'Patrocinadores - Rio Pipeline'
          }
        </title>

        {language === 'en_US'
          ? <link rel="canonical" href="/sponsors" />
          : <link rel="canonical" href="/patrocinadores" />
        }
      </Helmet>
      <h1 style={{ position: 'absolute', display: 'none' }}>Rio Pipeline - conference {'&'} exhibition</h1>
      <Breadcump customPath={
        language === 'en_US'
          ? [{ link: 'sponsors', name: 'Sponsors' }]
          : [{ link: 'patrocinadores', name: 'Patrocinadores' }]
      } />
      <Container>
        <GridSponsors />
        <BannerForm />
      </Container>
    </Fragment>
  )
}

export default Sponsors
