import React, { Fragment } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useLanguage } from '../../hooks/useLanguage'
import { ContainerPageNotFound } from '../../styles/page404'
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  const { language } = useLanguage()
  return (
    <Fragment>
      <Helmet>
        <title>not found - Rio Pipeline</title>
      </Helmet>
      <Router>
        <Switch>
          <ContainerPageNotFound>
            <h1>
              {language === 'en_US'
                ? 'Oops...! 404 something went wrong'
                : 'Oops..! 404 Algo deu errado'}
            </h1>
            <p>{language === 'en_US'
              ? 'Looks like you accessed the wrong page on our server'
              : 'Parece que você acessou a página errada em nosso servidor'}
            </p>
          </ContainerPageNotFound>
        </Switch>
      </Router>
    </Fragment>
  )
}

export default PageNotFound;