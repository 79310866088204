import styled, { keyframes } from 'styled-components'

const showElement = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`

const hiddenElement = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`

export const Header = styled.header`
  position: sticky;
  width: 100%;
  max-width: 100%;
  top: 0;
  z-index: 99999;

  background-color: var(--white);
  border-bottom: 3px solid orange;

  .header-top {
      background-color: orange;
      color: #fff;
      height: 32px;

      &__content {
          height: 100%;
          width: 1366px;
          max-width: 100%;
          margin: 0 auto;
          padding: 0 2.5rem;

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;

          a {
              font-family: 'Open Sans', sans-serif;
              font-size: 0.875rem;
              font-weight: 400;
              color: #fff;

              img {
                  display: block;
              }

              & + a {
                  margin-left: 1.5rem;
              }
          }
          
      }
  }
  

  .header-nav {
      width: 1366px;
      height: 80px;
      max-width: 100%;
      margin: 0 auto;
      padding: 0 2rem;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      #logo {
          display: block;
          width: 100%;
          max-width: 240px;
          height: auto;
      }

      .nav-bar{
          display: flex;
          align-items: center;
          height: 100%;
          
          ul {
              list-style: none;
              padding: 0;
              margin: 0;

              width: 100%;
              height: 100%;

              display: flex;
              align-items: flex-end;
              font-size: 0.875rem;            
  
              li {
                  position: relative;
                  margin-bottom: 3px; 
                  height: 59%;                

                  & + li {
                    margin-left: 2rem;
                  }

                  a {
                    text-transform: uppercase;
                    border-bottom: 5px solid transparent;
                    padding-bottom: 26px;
                    transition: all .3s ease;

                    &:hover, &.active {
                      color: var(--orange);
                      border-color: var(--orange);
                    }

                    &.spotlight-link {
                      border: 1px solid #000;
                      padding: 8px 15px;
                      font-weight: 700;

                      &:hover, &.active {
                        border-color: var(--orange);
                        background-color: var(--orange);
                        color: #000;
                      }
                    }
                  }

                  & > ul {
                      position: absolute;
                      top: 100%;
                      left: 0;
                      padding: 0;
                      margin-top: 0;
                      display: none;
                      flex-direction: column;
                      align-items: flex-start;
                      width: max-content;
                      height: initial;

                      background-color: #fff;
                      padding: 10px 20px; // verificar valores XD
                      border-bottom-left-radius: 8px;
                      border-bottom-right-radius: 8px;
                      border: 1px solid orange;

                      & > li {
                        margin: 0;
                        padding: 0;
                        height: initial;
                        border: none;

                        a {
                          text-transform: capitalize;
                          border: none;
                        }
                      }
                  }

                  &:hover {
                      ul {
                          display: flex;
                      }
                  }

              }
              .nav-item {
                .btn-yellow{
                  position: relative;
                  border: solid thin var(--orange);
                  font-weight: 600;
                  color: #FFF;
                  padding: 0.5rem 1rem;
                  padding: 0.7rem 1rem;
                  text-transform: uppercase;
                  margin-top: 1rem;
                  background-color: var(--orange);
                }
              }
              .nav-item{
                .btn-yellow:hover{
                  background-color: var(--white);
                  color: var(--orange);
                }
              }
          }            
      }
  }

  

  .mobile {
    display: none !important; 

    font-size: 25px;
  }

  @media (max-width: 1024px) {
    
    .mobile {
      display: block !important;  
 
     
    }

    .header-nav  {
        padding: 1rem 2.5rem;
        

        #logo {
            max-width: 165px;
        }

     
         .nav-bar  {
            position: absolute;
            top: 83px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9999;

            width: 100%;
            height: calc(100vh - 83px);
            overflow-y: auto;
            background-color: #fff;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem 2rem;

            &.show {
              animation: ${showElement} .3s ease;
              animation-fill-mode: forwards;

            }

            &.hidden {
              animation: ${hiddenElement} .3s ease;
              animation-fill-mode: forwards;
            }

            ul {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 90px;

                li {
                  height: auto;
                  border: none;

                  & + li {
                    margin-left: 0;
                    margin-top: 1rem;
                  }

                  a {
                    font-size: 1.3376rem;
                    text-transform: capitalize;
                    border: none;
                    padding: 0;
                  }
                  a:after {
                    height: 2px;
                    margin-top: 0.5rem;
                  }

                  &.haveSubItems {
                    margin-top: 0;
                    
                    & > a {
                      display: none;
                    }
                  }

                  ul {
                    position: relative;
                    top: inherit;
                    display: flex;
                    border: none;
                    border-radius: none;
                    padding: 0;

                    li {
                      margin-top: 1rem; 
                    }
                  }
                }
            }               
        }
    }
    
  }
`


