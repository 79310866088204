import styled from 'styled-components';

export const Container = styled.div`
  width: 1366px;
  max-width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
`;


export const Content = styled.div`
  position: relative;

  .text-message {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem;
    color: #000;
    margin: 2rem 0;
  }

  h1 {
    font-family: "Open sans", sans-serif;
    font-size: 2.25rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: 1rem;
  }

  .container-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.625rem;

    .content-row  {
      display: grid;
      grid-template-columns: repeat(2, auto);
      column-gap: 15px;

      .category {
        font-family: "Open sans";
        font-size: 1rem;
        font-weight: 700;
        color: #6F8080;
      }

      .date {
        font-family: "Open sans";
        font-size: 1rem;
        color: #000;

        &:before {
          content: '|';
          margin-right: 15px;
          color: var(--orange);
        }
      }

      p {
        font-family: "Open sans";
        font-size: 0.875rem;
        font-weight: 700;
        color: #000;
        margin: 0;
      }
    }
  }
  
  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .content {
    margin-top: 1rem;
    margin-bottom: 4rem;
    font-family: "Open sans";
    font-size: 1.125rem;

    h1, h2, h3, h4, h5, h6 {
      font-family: "Open sans";
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0 0 1.5rem;
    }

    p {
      font-family: "Open sans";
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 30px;
      margin: 0;
      margin-bottom: 1rem;
    }

    strong {
      color: var(--orange);
    }

    a {
      text-decoration: underline;
      color: var(--orange);
    }

    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  @media screen and (max-width: 990px) {
    h1 {
      font-size: 2.0064rem;
    }
    .content {
     p {
      font-size: 1.1704rem;
     }
      h1, h2, h3, h4, h5, h6  {
       font-size:  1.5048rem;
     } 
    }
  }
`;

export const PostRelated = styled.div`
  background-color: #F9F9F9;
  contain: content;
  padding-bottom: 60px;

  & > h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    width: 1366px;
    max-width: 100%;
    margin: 2rem auto; 
    padding: 0 2rem;   
  }

  .post-related {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 1366px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 2rem;
    row-gap: 30px;
    column-gap: 15px;

    .post {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 30px;
      border-bottom: 1px solid #E9E9E9;
      padding-bottom: 30px;

      &__img {
        width: 210px;
        height: 150px;
        border-radius: 10px;
        overflow: hidden;
        background-color: #ccc;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 20px;

        header {
          display: flex;
          flex-direction: column-reverse;
          margin-top: 1rem;

          h2 {
            font-family: 'Open Sans', sans-serif;
            font-size: 1.125rem;
            font-weight: 600;
            color: #000000;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          a {
            h3 {
              font-family: 'Open Sans', sans-serif;
              font-size: 0.875em;
              font-weight: 700;
              color: #6F8080;
            }
          }
        }

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          a {
            font-family: 'Open Sans', sans-serif;
            font-size: 0.875em;
            font-weight: 700;
            color: var(--orange);
            text-transform: uppercase;
          }

          .social-media-share {
            display: flex;
            align-items: center;
            column-gap: 10px;
          }
        }
      }
    }

    @media screen and (max-width: 990px) {
      grid-template-columns: 1fr;

      .post {
        &__img {
          display: none;
        }

        &__content {
          padding-right: 0;

          header {
            margin: 0;
          }
          .media-item {
            display: none;
          }
        }
      }
    }
  }
`