import styled from "styled-components";

export const ContainerAuthor = styled.section`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 20px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: start;
  column-gap: 30px;

  .content-title {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 30px;
    row-gap: 15px;

    figure {
      width: 275px;
      height: 370px;
      margin: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      } 
    }

    .content {
      h2 {
        font-family: "Open Sans", sans-serif;
        font-size: 1.5rem;
        font-weight: 700;
        color: #000;
        line-height: 2rem;
        margin: 0;
      }

      span {
        font-size: 1.25rem;
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        text-transform: capitalize;
        color: #000000;
        line-height: 2rem;
        margin: 0;
      }

      h3 {
        font-family: "Open Sans", sans-serif;
        font-size: 1.25rem;
        font-weight: 700;
        color: #000;
        line-height: 2rem;
        margin: 0;
      }

      .reverse {
        margin-top: 1rem;

        .content-description {
          margin-bottom: 1.5rem;

          p {
            font-family: 'Open Sans ligth', sans-serif;
            font-size: 1rem;
            font-weight: 300;
            line-height: 1.5rem;
            color: #000000;
            margin: 0;
          }
        }
      }      
    }

    .content-mobile {
      display: none;
      grid-column-start: 1;
      grid-column-end: 3;
      border-bottom: 1px solid #E0E2E2;

      p {
        font-family: 'Open Sans ligth', sans-serif;
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.5rem;
        color: #000000;
        margin: 0;
        margin-bottom: 25px;
      }
    }
  }

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;
    row-gap: 30px;

    .content-title {
      figure {
        width: 165px;
        height: 225px;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 1.5rem;

        .reverse {
          .content-description {
            display: none;
          }
        }        
      }

      .content-mobile {
        display: block;
      }
    }
  }
`;
