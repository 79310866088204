//Tela sobre o evento
import React, { Fragment, useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'

import BannerParallax from '../../components/BannerParallax'
import Breadcump from '../../components/Breadcump'
import AuthorCard from '../../components/AuthorCard'
import CustonCards from '../../components/CustonCards'
import Player from '../../components/Player'
import { Container, ContainerPlayer } from './styles'
import { GET_COMMITTEE_ORGANIZING } from '../../queries/pages'
import { client } from '../../Services/wordpress'
import { formatedComites } from '../../utils/formatedData'
import { useLanguage } from '../../hooks/useLanguage'
import { Helmet } from "react-helmet";

const About = () => {
  const { language } = useLanguage()
  const [cards, setCards] = useState([])

  useEffect(() => {
    getDate()
  }, [])

  async function getDate() {
    const res = await client.query({
      query: GET_COMMITTEE_ORGANIZING
    })

    const { data } = res
    const newData = data.comitesOrganizadores.nodes.map(formatedComites)

    setCards(newData)
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? 'The event - Rio Pipeline'
            : 'O Evento - Rio Pipeline'
          }
        </title>

        {language === 'en_US'
          ? <link rel="canonical" href="/the-event" />
          : <link rel="canonical" href="/o-evento" />
        }
      </Helmet>
      <Breadcump customPath={
        language === 'en_US'
          ? [{ link: 'the-event', name: 'The event' }]
          : [{ link: 'o-evento', name: 'O evento' }]
      } />
      <Container>
        <h1>
          {language === 'en_US'
            ? 'The event'
            : 'O Evento'}
        </h1>
      </Container>
      <ContainerPlayer>
        <Player />
      </ContainerPlayer>
      <BannerParallax >
        {/* <h2 className="content-text">
          {language === 'en_US'
            ? 'Rio Pipeline Gallery 2019'
            : 'Galeria Rio Pipeline 2019'}
        </h2>
        <span className="content-text">
          {language === 'en_US'
            ? 'See what happened in the 2019 edition'
            : 'Veja o que rolou na edição 2019'}
        </span> */}
        {/* <Link to="/">
          <button className='btn--outline'>
            {language === 'en_US'
              ? 'read more'
              : 'Galeria de fotos'}
          </button>
        </Link> */}
      </BannerParallax>
      <Container>
        <h2>
          {language === 'en_US'
            ? 'read more'
            : 'Comitê Organizador'}
        </h2>
        <AuthorCard cards={cards} type="organizador" />
        <CustonCards cards={cards} type="organizador" />
      </Container>
    </Fragment>
  )
}

export default About

