import styled from 'styled-components'

import imageBanner from '../../assests/images/main-banner.png'

export const Banner = styled.div`
  position: relative;
  min-height: 160px;
  width: 100%;
  background-image: url(${imageBanner});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  display: flex;
  align-items: center;

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: rgba(0,0,0,.3);
  }

  div {
    position: relative;
    z-index: 0;
    width: 1366px;
    max-width: 100%;
    margin: 1rem auto;
    padding: 0 2rem;

    h1 {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-size: 2.25rem;
      color: #fff;
    }
  }
`