/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useLanguage } from '../../hooks/useLanguage'
import { GET_POST } from '../../queries/pages'
import { client } from '../../Services/wordpress'
import SocialShare from '../SocialShare'

import { Container, Content, PostRelated } from './styles'

function NewsPost() {
  const { language } = useLanguage()
  let history = useHistory();
  const { slug } = useParams()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (slug) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  useEffect(() => {
    if (loading === false) {
      if (data.translatedPost) {
        setData({})
        setLoading(true)
        if (language === 'pt_BR') {
          history.push(`/noticias/${data.translatedPost}`)
        } else {
          history.push(`/news/${data.translatedPost}`)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  async function getData() {
    const res = await client.query({
      query: GET_POST,
      variables: { slug }
    })

    const { data } = res

    if (data.postBy) {
      const newData = {
        id: data.postBy.id,
        title: data.postBy.title,
        content: data.postBy.content,
        date: new Date(data.postBy.date).toLocaleDateString('pt-br', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        image: {
          url: data.postBy.featuredImage?.node.mediaItemUrl,
          alt: data.postBy.featuredImage?.node.altText,
        },
        category: {
          id: data.postBy.categories.nodes[0].categoryId,
          title: data.postBy.categories.nodes[0].name,
          slug: data.postBy.categories.nodes[0].slug,
        },
        translatedPost: data.postBy.translated[0]?.slug || null,
        postRelated: data.postBy.afcFieldsPosts.materiasRelacionadas?.map(post => {
          return {
            id: post.id,
            title: post.title,
            slug: post.slug,
            image: {
              url: post.featuredImage?.node.mediaItemUrl,
              alt: post.featuredImage?.node.altText,
            },
            categories: post.categories.nodes.map(category => {
              return {
                id: category.categoryId,
                title: category.name,
                slug: category.slug,
              }
            }),
          }
        })
      }

      setData(newData)
      setLoading(false)
    } else {
      history.push(`/404`);
    }
  }

  return (
    <Fragment>
      <Container>
        <Content>
          {loading === true && (<p className="text-message">Loading...</p>)}

          {loading === false && (
            <Fragment>
              <h1>{data?.title}</h1>

              <div className="container-row">
                <div className="content-row">
                  <Link
                    to={
                      language === 'pt_br'
                        ? `/categoria/${data?.category?.slug}`
                        : `/category/${data?.category?.slug}`
                    }
                    className="category"
                  >
                    {data?.category?.title}
                  </Link>
                  <p className="date">{data?.date}</p>
                </div>

                <SocialShare slug={
                  language === 'pt_BR'
                    ? `noticias/${slug}`
                    : `news/${slug}`
                }
                  page={true} />
              </div>
            </Fragment>
          )}

          {data?.image?.url && (
            <img className="img-main" src={data?.image?.url} alt={data?.image?.alt} />
          )}

          <div className="content" dangerouslySetInnerHTML={{ __html: data?.content }} />
        </Content>
      </Container>

      {data?.postRelated?.length > 0 && (
        <PostRelated>
          <h3>Notícias relacionadas</h3>

          <div className="post-related">
            {data?.postRelated?.map(post => (
              <div key={post.id} className='post'>
                <Link to={
                  language === 'pt_BR'
                    ? `/noticias/${post.slug}`
                    : `/news/${post.slug}`
                }
                  className='post__img'
                >
                  <img src={post.image.url} alt={post.image.alt} />
                </Link>

                <div className='post__content' >
                  <header>
                    <h2>{post.title}</h2>

                    <Link to={
                      language === 'pt_BR'
                        ? `/categoria/${post.categories[0]?.slug}`
                        : `/category/${post.categories[0]?.slug}`
                    }>
                      <h3>{post.categories[0].title}</h3>
                    </Link>
                  </header>
                  <div className="content-media">

                    <div className="media">
                      <Link to={
                        language === 'pt_BR'
                          ? `/noticias/${post.slug}`
                          : `/news/${post.slug}`
                      }>
                        {language === "en_US"
                          ? "Read more"
                          : "Ler mais"}
                      </Link>
                    </div>
                    <div className="media-item">
                      <SocialShare slug={
                        language === 'pt_BR'
                          ? `/noticias/${slug}`
                          : `/news/${slug}`
                      } />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </PostRelated>
      )}
    </Fragment>
  )
}

export default NewsPost
