import React from "react";
import { useLanguage } from "../../hooks/useLanguage";
import {
  ContainerTextWrapper,
  ContainerText,
  HotelContainerWrapper,
  HotelContainer,
  HotelContent,
  HotelsWrapper,
  HotelItem,
  Banner,
} from "./styles";
import Breadcump from '../../components/Breadcump'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Index() {
  const { language } = useLanguage();

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <Banner>
        <div>
          <span>
            {language === "en_US" ? "Discover Rio" : "Descubra o Rio"}
          </span>
        </div>
      </Banner>
      <Breadcump customPath={
        language === 'en_US'
          ? [{ link: 'know-the-city', name: 'Know the City' }]
          : [{ link: 'conheca-a-cidade', name: 'Conheça a Cidade' }]
      } />
      <ContainerTextWrapper>
        <ContainerText>
          <h2>
            {language === "en_US" ? "Plan your trip" : "Planeje sua viagem"}
          </h2>
          <p>
            {language === "en_US"
                ?
              `Few days left to connect with professionals from all over the world. Check out our partner hotels and some information about the event venue.`
                :
              `Falta pouco para se conectar com profissionais de todo o mundo. Confira as opções de hotéis parceiros e informações sobre o local do evento.`}
          </p>
        </ContainerText>
        <Slider {...settings}>
          <img
            src={require("../../assests/images/Rio06.jpg").default}
            alt={"Rio"}
          />
          <img
            src={require("../../assests/images/Rio01.jpg").default}
            alt={"Rio"}
          />
          <img
            src={require("../../assests/images/Rio02.jpg").default}
            alt={"Rio"}
          />
          <img
            src={require("../../assests/images/Rio03.jpg").default}
            alt={"Rio"}
          />
          <img
            src={require("../../assests/images/Rio04.jpg").default}
            alt={"Rio"}
          />
          <img
            src={require("../../assests/images/Rio05.jpg").default}
            alt={"Rio"}
          />
        </Slider>
      </ContainerTextWrapper>
      <HotelsWrapper>
        <HotelItem>
            <a href="https://expomag.com.br/">
              <img
                className="hotel-item-image"
                src='https://painel.riopipeline.com.br/wp-content/uploads/2023/08/LOCAL-DO-EVENTO-PLANEJE-SUA-VIAGEM.png'
                alt={"EXPOMAG"}
              />
            </a>
            <div className="hotel-info">
              <strong>{language === 'en_US'? 'Local Venue' : 'LOCAL DO EVENTO'}</strong>
              <span className="hotelTitle">
                <a href="https://expomag.com.br/">EXPOMAG</a>
              </span>
              <p>
                <br />
                {language === "en_US" ? (
                  <strong>Address: </strong>
                ) : (
                  <strong>Endereço: </strong>
                )}
                {language === "en_US" ? (
                  "R. Beatriz Larragoiti Lucas, s/n - Cidade Nova, Rio de Janeiro - RJ, 20211-175. Nearest subway station: Estácio (line 1-4, orange line)"
                ) : (
                  " R. Beatriz Larragoiti Lucas, s/n - Cidade Nova, Rio de Janeiro - RJ, 20211-175. Estação de metrô mais próxima: Estácio (linha 1-4, laranja)"
                )}
              </p>
              <a
                className="linkToHotel"
                href="https://expomag.com.br/"
              >
                {language === "en_US" ? "Read More" : "Saiba Mais"}
              </a>
            </div>
        </HotelItem>
      </HotelsWrapper>
      <HotelContainerWrapper>
        <HotelContainer>
          <a href="https://www.fairmont.net.br/copacabana-rio/">
            <Slider {...settings}>
              <img
                src={require("../../assests/images/FairMont3289.jpg").default}
                alt={"FairMont"}
              />
              <img
                src={
                  require("../../assests/images/FairMont3417 copy.jpg").default
                }
                alt={"FairMont"}
              />
              <img
                src={
                  require("../../assests/images/FairMont4010 copy.jpg").default
                }
                alt={"FairMont"}
              />
              <img
                src={
                  require("../../assests/images/FairMont4729 copy.jpg").default
                }
                alt={"FairMont"}
              />
              <img
                src={require("../../assests/images/Marine Restô.jpg").default}
                alt={"FairMont"}
              />
            </Slider>
          </a>
          
          <HotelContent>
            <h2>
              {language === "en_US"
                ? "Official Rio Pipeline 2023 Hotel"
                : "Hotel oficial da Rio Pipeline 2023"}
            </h2>
            <hr />
            <div className="logo-contact">
              <img
                src={
                  require("../../assests/images/Logo Fairmont Rio - Black.png")
                    .default
                }
                alt={"FairMont"}
              />
            </div>
            <hr style={{ width: "30%" }} />
            <p>
              {language === "en_US"
                ? `Stay at the event's official hotel, in one of the world's most famous neighborhoods, with special conditions for participants.`
                : `Hospede-se no hotel oficial do evento, em um dos bairros mais famosos do mundo, com condições especiais para participantes.`}
            </p>
            <a href={"https://www.fairmont.net.br/copacabana-rio/"}>
              {language === "en_US"
                ? "Make your reservation"
                : "Faça sua reserva"}
            </a>
          </HotelContent>
        </HotelContainer>
      </HotelContainerWrapper>
      <HotelsWrapper>
        <p className="hotels-title">
          {language === "en_US"
            ? `Check out our partners and find the best hotel for your stay in Rio de Janeiro.`
            : `Confira a nossa seleção de parceiros e descubra a melhor opção de hotel para sua estadia no Rio de Janeiro.`}
        </p>
        
        <HotelItem>
          <a href="https://www.ritzcopacabana.com.br/">
            <img
              className="hotel-item-image"
              src={
                require("../../assests/images/foto Ritz Copacabana.jpg").default
              }
              alt={"Ritz Copacabana"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://www.ritzcopacabana.com.br/">Ritz Copacabana</a>
            </span>
            <p>
              <span>
                <strong>
                  {language === "en_US"
                    ? "Contact for reservation: "
                    : "Central de Reservas, e-mail: "}
                </strong>
                <a href="mailto:reservas@ritzcopacabana.com.br">
                  reservas@ritzcopacabana.com.br
                </a>
              </span>
              <br />
              {language === "en_US" ? (
                <strong>Address: </strong>
              ) : (
                <strong>Endereço: </strong>
              )}
              R. Xavier da Silveira, 13 - Copacabana, Rio de Janeiro - RJ,
              22061-010
            </p>
            <a
              className="linkToHotel"
              href="https://www.ritzcopacabana.com.br/"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://www.ritzleblon.com.br/">
            <img
              className="hotel-item-image"
              src={require("../../assests/images/foto ritz leblon.jpg").default}
              alt={"Ritz Leblon"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://www.ritzleblon.com.br/">Ritz Leblon</a>
            </span>
            <p>
              <span>
                <strong>
                  {language === "en_US"
                    ? "Contact for reservation: "
                    : "Central de Reservas, e-mail: "}
                </strong>
                <a href="mailto:reservas@ritzhotel.com.br">
                  reservas@ritzhotel.com.br
                </a>
              </span>
              <br />
              {language === "en_US" ? (
                <strong>Address: </strong>
              ) : (
                <strong>Endereço: </strong>
              )}
              Av. Ataulfo de Paiva, 1280 - Leblon, Rio de Janeiro - RJ,
              22440-031
            </p>
            <a className="linkToHotel" href="https://www.ritzleblon.com.br/">
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://www.hotelvermont.com.br/">
            <img
              className="hotel-item-image"
              src={
                require("../../assests/images/foto vermont ipanema.jpg").default
              }
              alt={"vermont ipanema"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://www.hotelvermont.com.br/">
                Hotel Vermont – Ipanema
              </a>
            </span>
            <p>
              <span>
                <strong>
                  {language === "en_US"
                    ? "Contact for reservation: "
                    : "Central de Reservas, e-mail: "}
                </strong>
                <a href="mailto:gerencia@hotelvermont.com.br">
                  gerencia@hotelvermont.com.br
                </a>
              </span>
              <br />
              {language === "en_US" ? (
                <strong>Address: </strong>
              ) : (
                <strong>Endereço: </strong>
              )}
              R. Visc. de Pirajá, 254 - Ipanema, Rio de Janeiro - RJ, 22410-000
            </p>
            <a className="linkToHotel" href="https://www.hotelvermont.com.br/">
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://all.accor.com/hotel/5691/index.pt-br.shtml">
            <img
              className="hotel-item-image"
              src={
                require("../../assests/images/foto ibis santos dumont.jpg")
                  .default
              }
              alt={"ibis santos dumont"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://all.accor.com/hotel/5691/index.pt-br.shtml">
                Ibis Santos Dumont
              </a>
            </span>
            {language === "en_US" ? (
              <p>
                <span>
                  <strong>Reservation Center, e-mail: </strong>
                  <a href="mailto:h5691-re@accor.com">h5691-re@accor.com</a>
                </span>
                <br />
                <strong>Contact for reservation: </strong>
                <span style={{ whiteSpace: "nobreak" }}>
                  93500-2400
                </span> or{" "}
                <span style={{ whiteSpace: "nobreak" }}>08003018000</span>
                <br />
                <br />
                Use our promotional code and guarantee an exclusive discount!
                <br />
                <strong>Coupon: </strong>EXPOMAG
              </p>
            ) : (
              <p>
                <span>
                  <strong>Central de Reservas, e-mail: </strong>
                  <a href="mailto:h5691-re@accor.com">h5691-re@accor.com</a>
                </span>
                <br />
                <strong>Contato para reserva: </strong>
                <span style={{ whiteSpace: "nobreak" }}>
                  93500-2400
                </span> ou{" "}
                <span style={{ whiteSpace: "nobreak" }}>08003018000</span>
                <br />
                <br />
                Utilize nosso código promocional e garanta um desconto
                exclusivo!
                <br />
                <strong>Cupom: </strong>EXPOMAG
              </p>
            )}

            <a
              className="linkToHotel"
              href="https://all.accor.com/hotel/5691/index.pt-br.shtml"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://all.accor.com/hotel/6522/index.pt-br.shtml">
            <img
              className="hotel-item-image"
              src={
                require("../../assests/images/foto novotel santos dumont.jpg")
                  .default
              }
              alt={"Novotel Santos Dumont"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://all.accor.com/hotel/6522/index.pt-br.shtml">
                Novotel Santos Dumont
              </a>
            </span>
            {language === "en_US" ? (
              <p>
                <span>
                  <strong>Reservation Center, e-mail: </strong>
                  <a href="mailto:h6522-re@accor.com">h6522-re@accor.com</a>
                </span>
                <br />
                <strong>Contact for reservation: </strong>
                <span style={{ whiteSpace: "nobreak" }}>
                  93500-2400
                </span> or{" "}
                <span style={{ whiteSpace: "nobreak" }}>08003018000</span>
                <br />
                <br />
                Use our promotional code and guarantee an exclusive discount!
                <br />
                <strong>Coupon: </strong>EXPOMAG
              </p>
            ) : (
              <p>
                <span>
                  <strong>Central de Reservas, e-mail: </strong>
                  <a href="mailto:h6522-re@accor.com">h6522-re@accor.com</a>
                </span>
                <br />
                <strong>Contato para reserva: </strong>
                <span style={{ whiteSpace: "nobreak" }}>
                  93500-2400
                </span> ou{" "}
                <span style={{ whiteSpace: "nobreak" }}>08003018000</span>
                <br />
                <br />
                Utilize nosso código promocional e garanta um desconto
                exclusivo!
                <br />
                <strong>Cupom: </strong>EXPOMAG
              </p>
            )}

            <a
              className="linkToHotel"
              href="https://all.accor.com/hotel/6522/index.pt-br.shtml"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://all.accor.com/hotel/9327/index.pt-br.shtml">
            <img
              className="hotel-item-image"
              src={
                require("../../assests/images/foto ibis budget copacabana.jpg")
                  .default
              }
              alt={"Ibis Budget Copacabana"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://all.accor.com/hotel/9327/index.pt-br.shtml">
                Ibis Budget Copacabana
              </a>
            </span>
            {language === "en_US" ? (
              <p>
                <span>
                  <strong>Reservation Center, e-mail: </strong>
                  <a href="mailto:h6522-re@accor.com">h6522-re@accor.com</a>
                </span>
                <br />
                <strong>Contact for reservation: </strong>
                <span style={{ whiteSpace: "nobreak" }}>
                  93500-2400
                </span> or{" "}
                <span style={{ whiteSpace: "nobreak" }}>08003018000</span>
                <br />
                <br />
                Use our promotional code and guarantee an exclusive discount!
                <br />
                <strong>Coupon: </strong>EXPOMAG
              </p>
            ) : (
              <p>
                <span>
                  <strong>Central de Reservas, e-mail: </strong>
                  <a href="mailto:h6522-re@accor.com">h6522-re@accor.com</a>
                </span>
                <br />
                <strong>Contato para reserva: </strong>
                <span style={{ whiteSpace: "nobreak" }}>
                  93500-2400
                </span> ou{" "}
                <span style={{ whiteSpace: "nobreak" }}>08003018000</span>
                <br />
                <br />
                Utilize nosso código promocional e garanta um desconto
                exclusivo!
                <br />
                <strong>Cupom: </strong>EXPOMAG
              </p>
            )}

            <a
              className="linkToHotel"
              href="https://all.accor.com/hotel/9327/index.pt-br.shtml"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://all.accor.com/hotel/6497/index.pt-br.shtml">
            <img
              className="hotel-item-image"
              src={
                require("../../assests/images/foto ibis copacabana posto 2.jpg")
                  .default
              }
              alt={"Ibis Copacabana Posto 2"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://all.accor.com/hotel/6497/index.pt-br.shtml">
                Ibis Copacabana Posto 2
              </a>
            </span>
            {language === "en_US" ? (
              <p>
                <span>
                  <strong>Reservation Center, e-mail: </strong>
                  <a href="mailto:h6497-re@accor.com">h6497-re@accor.com</a>
                </span>
                <br />
                <strong>Contact for reservation: </strong>
                <span style={{ whiteSpace: "nobreak" }}>
                  93500-2400
                </span> or{" "}
                <span style={{ whiteSpace: "nobreak" }}>08003018000</span>
                <br />
                <br />
                Use our promotional code and guarantee an exclusive discount!
                <br />
                <strong>Coupon: </strong>EXPOMAG
              </p>
            ) : (
              <p>
                <span>
                  <strong>Central de Reservas, e-mail: </strong>
                  <a href="mailto:h6497-re@accor.com">h6497-re@accor.com</a>
                </span>
                <br />
                <strong>Contato para reserva: </strong>
                <span style={{ whiteSpace: "nobreak" }}>
                  93500-2400
                </span> ou{" "}
                <span style={{ whiteSpace: "nobreak" }}>08003018000</span>
                <br />
                <br />
                Utilize nosso código promocional e garanta um desconto
                exclusivo!
                <br />
                <strong>Cupom: </strong>EXPOMAG
              </p>
            )}

            <a
              className="linkToHotel"
              href="https://all.accor.com/hotel/6497/index.pt-br.shtml"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://all.accor.com/hotel/7547/index.pt-br.shtml">
            <img
              className="hotel-item-image"
              src={
                require("../../assests/images/foto ibis botafogo.jpg").default
              }
              alt={"Ibis Botafogo"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://all.accor.com/hotel/7547/index.pt-br.shtml">
                Ibis Botafogo
              </a>
            </span>
            {language === "en_US" ? (
              <p>
                <span>
                  <strong>Reservation Center, e-mail: </strong>
                  <a href="mailto:h7547-re@accor.com">h7547-re@accor.com</a>
                </span>
                <br />
                <strong>Contact for reservation: </strong>
                <span style={{ whiteSpace: "nobreak" }}>
                  93500-2400
                </span> or{" "}
                <span style={{ whiteSpace: "nobreak" }}>08003018000</span>
                <br />
                <br />
                Use our promotional code and guarantee an exclusive discount!
                <br />
                <strong>Coupon: </strong>EXPOMAG
              </p>
            ) : (
              <p>
                <span>
                  <strong>Central de Reservas, e-mail: </strong>
                  <a href="mailto:h7547-re@accor.com">h7547-re@accor.com</a>
                </span>
                <br />
                <strong>Contato para reserva: </strong>
                <span style={{ whiteSpace: "nobreak" }}>
                  93500-2400
                </span> ou{" "}
                <span style={{ whiteSpace: "nobreak" }}>08003018000</span>
                <br />
                <br />
                Utilize nosso código promocional e garanta um desconto
                exclusivo!
                <br />
                <strong>Cupom: </strong>EXPOMAG
              </p>
            )}

            <a
              className="linkToHotel"
              href="https://all.accor.com/hotel/7547/index.pt-br.shtml"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://www.selina.com/pt/brazil/lapa-rio-de-janeiro/">
            <img
              className="hotel-item-image"
              src={require("../../assests/images/foto selina lapa.jpg").default}
              alt={"Selina Lapa"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://www.selina.com/pt/brazil/lapa-rio-de-janeiro/">
                Selina Lapa
              </a>
            </span>
            {language === "en_US" ? (
              <p>
                Use our promotional code and guarantee an exclusive discount!
                <br />
                <strong>Coupon: </strong>RIOPIPELINE23
              </p>
            ) : (
              <p>
                Utilize nosso código promocional e garanta um desconto
                exclusivo!
                <br />
                <strong>Cupom: </strong>RIOPIPELINE23
              </p>
            )}

            <a
              className="linkToHotel"
              href="https://www.selina.com/pt/brazil/lapa-rio-de-janeiro/"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://www.hotel-bb.com/pt/hotel/rio-de-janeiro-copacabana-forte">
            <img
              className="hotel-item-image"
              src={
                require("../../assests/images/forte-reception-3-3963.png")
                  .default
              }
              alt={"B&B Copacabana Forte "}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://www.hotel-bb.com/pt/hotel/rio-de-janeiro-copacabana-forte">
                {"B&B Copacabana Forte"}
              </a>
            </span>
            {language === "en_US" ? (
              <p>
                Use our promotional code and guarantee an exclusive discount!
                <br />
                <strong>Coupon: </strong>RIOPIPELINE
              </p>
            ) : (
              <p>
                Utilize nosso código promocional e garanta um desconto
                exclusivo!
                <br />
                <strong>Cupom: </strong>RIOPIPELINE
              </p>
            )}

            <a
              className="linkToHotel"
              href="https://www.hotel-bb.com/pt/hotel/rio-de-janeiro-copacabana-forte"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://www.hotel-bb.com/pt/hotel/rio-de-janeiro-copacabana-posto-5">
            <img
              className="hotel-item-image"
              src={
                require("../../assests/images/foto b&b copacabana posto 5.jpg")
                  .default
              }
              alt={"B&B Copacabana Posto 5 "}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://www.hotel-bb.com/pt/hotel/rio-de-janeiro-copacabana-posto-5">
                {"B&B Copacabana Posto 5"}
              </a>
            </span>
            {language === "en_US" ? (
              <p>
                Use our promotional code and guarantee an exclusive discount!
                <br />
                <strong>Coupon: </strong>RIOPIPELINE
              </p>
            ) : (
              <p>
                Utilize nosso código promocional e garanta um desconto
                exclusivo!
                <br />
                <strong>Cupom: </strong>RIOPIPELINE
              </p>
            )}

            <a
              className="linkToHotel"
              href="https://www.hotel-bb.com/pt/hotel/rio-de-janeiro-copacabana-posto-5"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://rederiohoteis.com/arosa-hotel-na-lapa">
            <img
              className="hotel-item-image"
              src={require("../../assests/images/foto hotel arosa.jpg").default}
              alt={"Hotel Arosa"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://rederiohoteis.com/arosa-hotel-na-lapa">
                Hotel Arosa
              </a>
            </span>
            {language === "en_US" ? (
              <p>
                <span>
                  <strong>Reservation Center, e-mail: </strong>
                  <a href="mailto:reservas@rederiohoteis.com">
                    reservas@rederiohoteis.com
                  </a>
                </span>
                <br />
                Use our promotional code and guarantee an exclusive discount!
                <br />
                <strong>Coupon: </strong>107150
              </p>
            ) : (
              <p>
                <span>
                  <strong>Central de Reservas, e-mail: </strong>
                  <a href="mailto:reservas@rederiohoteis.com">
                    reservas@rederiohoteis.com
                  </a>
                </span>
                <br />
                Utilize nosso código promocional e garanta um desconto
                exclusivo!
                <br />
                <strong>Cupom: </strong>107150
              </p>
            )}
            <a
              className="linkToHotel"
              href="https://rederiohoteis.com/arosa-hotel-na-lapa"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://www.intercityhoteis.com.br/">
            <img
              className="hotel-item-image"
              src={
                require("../../assests/images/Hotel-Itercity.jpg")
                  .default
              }
              alt={"Hotel Yoo2"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://www.intercityhoteis.com.br/">
                Intercity Porto Maravilha
              </a>
            </span>
            {language === "en_US" ? (
              <p>
                25% discount for event participants using promocode!
                <br />
                <strong>Promocode: </strong>IBP2023
                <br/>
                <br />
                <strong>Address:</strong>
                <span style={{ whiteSpace: "nobreak" }}>
                R. Cordeiro da Graça, 598 - Santo Cristo, Rio de Janeiro - RJ, 20220-400
                </span>
                <br />
                <br />
              </p>
            ) : (
              <p>
                Desconto de 25% para participantes do evento utilizando o promocode!
                <br />
                <strong>Promocode: </strong>IBP2023
                <br/>
                <br/>
                <strong>Endereço:</strong>
                <span style={{ whiteSpace: "nobreak" }}>
                R. Cordeiro da Graça, 598 - Santo Cristo, Rio de Janeiro - RJ, 20220-400
                </span>
                <br />
              </p>
            )}

            <a
              className="linkToHotel"
              href="https://www.intercityhoteis.com.br/"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
        <HotelItem>
          <a href="https://yoo2.com/pt/">
            <img
              className="hotel-item-image"
              src={
                require("../../assests/images/Hotel-Yoo2.jpg")
                  .default
              }
              alt={"Hotel InterCity"}
            />
          </a>
          <div className="hotel-info">
            <span className="hotelTitle">
              <a href="https://yoo2.com/pt/">
                Yoo2
              </a>
            </span>
            {language === "en_US" ? (
              <p>
                25% discount for event participants using promocode!
                <br />
                <strong>Promocode: </strong>IBP2023
                <br/>
                <br />
                <strong>Address:</strong>
                <span style={{ whiteSpace: "nobreak" }}>
                Praia de Botafogo, 242 - Botafogo, Rio de Janeiro - RJ, 22250-040
                </span>
                <br />
                <br />
              </p>
            ) : (
              <p>
                Desconto de 25% para participantes do evento utilizando o promocode!
                <br />
                <strong>Promocode: </strong>IBP2023
                <br/>
                <br/>
                <strong>Endereço:</strong>
                <span style={{ whiteSpace: "nobreak" }}>
                Praia de Botafogo, 242 - Botafogo, Rio de Janeiro - RJ, 22250-040
                </span>
                <br />
              </p>
            )}

            <a
              className="linkToHotel"
              href="https://yoo2.com/pt/"
            >
              {language === "en_US" ? "Book Now" : "Reserve Agora"}
            </a>
          </div>
        </HotelItem>
      </HotelsWrapper>
    </>
  );
}

export default Index;
