import styled from 'styled-components';

export const ContainerThankYou = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
   width: 100%;
   height: 35vh;
   color: orange;

   p {
       font-size: 2rem;
      
   }
`;
