import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 2rem;


  & > h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 2.25rem;
    font-weight: 700;    
  }

  & > h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 2.25rem;
    font-weight: 700;  
    text-align: center;
    margin: 4rem 0;
  }

  @media screen and (max-width: 980px) {
    h2 {
      text-align: start;
    }
  }


  
`;

export const ContainerPlayer = styled.div`
.btn-player {
display: none;
}

`
