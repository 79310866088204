import { useLanguage } from "../../../hooks/useLanguage";

export default function ExpositorItem(data) {
  const { language } = useLanguage()
  const expositor = data.data

  const ESTANDE_TEXT = language === 'en_US' ? 'Booth' : 'Estande'

  return (
    <div className="expositor-item">
      {expositor.image.url && (
        <img src={expositor.image.url} alt={expositor.title} />
      )}
      <div className="expositor-item__content">
        <p className="expositor-item__name">{expositor.title}</p>
        <p className="expositor-item__box">{ESTANDE_TEXT}: {expositor.estande}</p>
        <a href={`https://${expositor.website}`} className="expositor-item__website">
          <span>Website</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
          >
            <g transform="translate(-2.5 -2.5)">
              <path
                d="M13.667,9.675v4.259A1.067,1.067,0,0,1,12.6,15H4.067A1.067,1.067,0,0,1,3,13.933V5.4A1.067,1.067,0,0,1,4.067,4.333h4.3M10.333,3h4A.667.667,0,0,1,15,3.667v4m-.333-4.333L8.255,9.745"
                fill="none"
                stroke="#fcaf17"
              />
            </g>
          </svg>
        </a>
      </div>
    </div>
  );
}
