import React, { useState, useEffect, useRef } from "react";
import { ShowSlide } from "./styles";

import { client } from "../../Services/wordpress";
import { GET_BANNERS } from "../../queries/pages";
import { useLanguage } from "../../hooks/useLanguage";

const delay = 10000;
export function Slideshow() {
  const { language } = useLanguage();
  const [banners, setBanners] = useState([]);
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const slideshowSlider = useRef(null);

  // Inicio Logica de transição de integração
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  async function getData() {
    try {
      const res = await client.query({
        query: GET_BANNERS,
        variables: { language },
      });
      const { data, errors } = res;

      if (errors) {
        console.error("request falied");
        return;
      }

      const newData = data.banners.nodes.map((banner) => {
        return {
          id: banner.id,
          title: banner.title,
          content: banner.content,
          image: {
            url: banner.featuredImage.node.mediaItemUrl,
            alt: banner.featuredImage.node.altText,
          },
        };
      });

      setBanners(newData);
    } catch (error) {
      console.error("request fail");
      // console.error(error) // TODO Habilitar somente em degub
    }
  }

  // Inicio Logica de transição de imagens
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    if (banners.length > 1) {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () =>
          setIndex((prevIndex) =>
            prevIndex === banners.length - 1 ? 0 : prevIndex + 1
          ),
        delay
      );

      return () => {
        resetTimeout();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, banners.length]);

  // Fim Logica de transição de imagens

  function handleClick(event){
    event.preventDefault();
    const target = event.currentTarget;
    const href = target.getAttribute("href");

    if(target.hasAttribute("href") && target?.classList?.contains("send-tagging")){
     
      try{
        
        //Traking do facebook
        window.fbq('track', 'PageView');
        
        //Traking do linkedin
        window.lintrk('track', { conversion_id: 13600961 });

        //Traking do google
        function gtag_report_conversion(url) {
            var callback = function () {
              if (typeof (url) != 'undefined') {
                window.location = url;
              }
            };
            window.gtag(
              'event', 'conversion', {
              'send_to': 'AW-11147243671/9wZ3CP_zk6AYEJfhtcMp',
              'transaction_id': '',
              'event_callback': callback
            });

            return false;
        }
        gtag_report_conversion(window.location.href);

      }catch(e){

        console.error(e);
      
      }
    }

    setTimeout(() => {    
      window.location.href = href;
    }, 100);

  }

  useEffect(() => {
    if(slideshowSlider?.current){
      const buttomBanners = document?.querySelectorAll(`.slide__content .content .inner_content a`);

      buttomBanners?.forEach((buttom) =>{
        buttom.addEventListener("click", handleClick);
      })
    }
  }, [banners]);
  

  if (banners.length === 0) return null;

  return (
    <ShowSlide>
      <div className="triangle-right" />
      <div
        className="slideshowSlider" ref={slideshowSlider}
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {banners.map((banner) => {
          return (
            <div key={banner.id} className="slide">
              <img
                onClick={() =>
                  document.getElementById("next-section").scrollIntoView()
                }
                src={banner.image.url}
                alt={banner.image.alt}
              />
              <div className="slide__content">
                <div className="content">
                  <h2
                    ref={(element) => {
                      if (element) element.innerHTML = banner.title;
                    }}
                  />
                  <div
                    className="inner_content"
                    dangerouslySetInnerHTML={{ __html: banner?.content }}
                  />
                </div>
                {banners.length > 1 && (
                  <div className="slideshowDots">
                    {banners.map((_, idx) => (
                      <div
                        key={idx}
                        className={`slideshowDot${
                          index === idx ? " active" : ""
                        }`}
                        onClick={() => {
                          setIndex(idx);
                        }}
                      ></div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="triangle-left" />
    </ShowSlide>
  );
}

export default Slideshow;
