import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 2rem;

  & > h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 2.25rem;
    font-weight:  700;
  }

  @media screen and (max-width: 990px) {
    h1 {
      font-size: 2.0064rem;
    }
  }

`


