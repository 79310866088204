import React, { Fragment } from 'react'
import Breadcump from '../../components/Breadcump'
import { Helmet } from "react-helmet"
import { useLanguage } from "../../hooks/useLanguage"
import {Container, CatainerImg, ImgMapa} from "./styles"


function Mapa() {
    const { language} = useLanguage();
    
    return(
        <Fragment>
            <Helmet>
                <title>Mapa | Rio Pipeline</title>
            </Helmet>
            <Container>
                <Breadcump customPath={
                  language === 'en_US'
                    ? [{ link: 'map', name: 'map' }]
                    : [{ link: 'mapa', name: 'mapa' }]
                } />
            </Container>
            <Container>
            { language === 'en_US' ? 
                        <h2> Exhibition hours: 12 to 20pm.</h2>
                    : 
                        <h2>Horário de funcionamento da exposição: 12 às 20h.</h2>
                    }
            </Container>
            <CatainerImg>
                <ImgMapa src="https://painel.riopipeline.com.br/wp-content/uploads/2023/08/MAPA-PNG-min.png" />
            </CatainerImg>

          </Fragment>
    )
}

export default Mapa;