import styled from "styled-components";

export const ContainerPlayer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 5rem;
  width: 1366px;
  max-width: 100%;
  margin: 0 auto;
  padding: 40px;

  .welcome__video {
    flex: 1 1 50%;

    iframe {
      width: 100%;
      max-width: 610px;
      height: 315px;
      display: block;
    }
  }

  .welcome__content {
    flex: 1 1 50%;

    h2 {
      font-size: 2rem;
    }

    .subtitle {
      font-family: "Open Sans", sans-serif;
      font-size: 1.7556rem;
      line-height: 2.1875rem;
      font-weight: 700;
      color: black;
      margin-bottom: 1.2rem;
    }

    p {
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      font-weight: 300;
      color: black;
      line-height: 28px;
      margin-left: 0;
    }

    a {
      display: inline-block;
      font-size: 0.875rem;
      font-weight: 600;
      color: #000000;
      padding: 12px 45px;
      text-transform: uppercase;
      margin-top: 1rem;
      background-color: var(--orange);
    }

    strong {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      font-family: "Open Sans";
    }
  }

  @media screen and (max-width: 990px) {
    flex-direction: column;
    padding: 20px;
    margin: auto;

    .welcome__video {
      width: 100%;
      flex: 1 1 auto;

      iframe {
        width: 100%;
        max-width: 100%;
      }
    }

    .welcome__content {
      flex: 1 1 auto;
      margin-top: 2rem;

      a {
        display: none;
      }
      .subtitle {
        font-size: 1.7556rem;
      }
    }
  }
`;
