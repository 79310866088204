import styled, { keyframes } from 'styled-components';

const showElement = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`

const hiddenElement = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`

export const Button = styled.div`
  position: fixed;
  right: 15px;
  bottom: 30px;
  height: 4rem;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;

  &.show {
    animation: ${showElement} .3s ease;
    animation-fill-mode: forwards;
  }

  &.hidden {
    animation: ${hiddenElement} .3s ease;
    animation-fill-mode: forwards;
  }
`
