import styled from 'styled-components'

export const Container = styled.div`
  width: 1366px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 2rem;

  h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2rem 0;
  }

  .loading-post {
    display: block;
    margin: 2rem auto;

    background: var(--orange);
    padding: 10px 60px;
    border: none;

    font-family: 'Open Sans', sans-serif;
    font-size: 0.75rem;
    font-weight: 700;
    color: #000000;

    &:disabled {
      opacity: .8;
    }
  }
`

export const CartContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 1366px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 0 2rem;
  row-gap: 30px;
  column-gap: 15px;

  .post {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 30px;
    border-bottom: 1px solid #E9E9E9;
    padding-bottom: 30px;

    &__img {
      width: 210px;
      height: 150px;
      border-radius: 10px;
      overflow: hidden;
      background-color: #ccc;
      margin: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 20px;

      header {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 1rem;

        h2 {
          font-family: 'Open Sans', sans-serif;
          font-size: 1.125rem;
          font-weight: 600;
          color: #000000;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        a {
          h3 {
            font-family: 'Open Sans', sans-serif;
            font-size: 0.875em;
            font-weight: 700;
            color: #6F8080;
          }
        }
      }

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
          font-family: 'Open Sans', sans-serif;
          font-size: 0.875em;
          font-weight: 700;
          color: var(--orange);
          text-transform: uppercase;
        }

        .social-media-share {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;

    .post {
      &__img {
        display: none;
      }

      &__content {
        padding-right: 0;

        header {
          margin: 0;
        }
      }
    }
  }
`