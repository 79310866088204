import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body, input, textarea, select, button {
    font-family: 'Open Sans', sans-serif;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  ul {
    text-decoration: none;
  }

  :root{
    --dark-bg: #000000;
    --orange: #FCAF17;
    --gray-1: #BCB4B4;
    --deep-dark: #1E1E1E;
    --gray: ##6F8080;
    --white : #FFFFFF;
    --black: black;
    --white-smoke: #f5f5f5;
  }

  html, body{
    font-family: 'Open Sans', sans-serif;
    background-color: var(---white);
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%; // 15px
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%; // 14px
    }
  }
`;


export default GlobalStyles;