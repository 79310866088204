import React, { Fragment } from 'react'
import Breadcump from '../../components/Breadcump'
import NewsPost from '../../components/Newpost'
import { Helmet } from "react-helmet";
import { useLanguage } from '../../hooks/useLanguage';

const Posts = () => {
  const { language } = useLanguage()

  return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? 'News - Rio Pipeline'
            : 'Notícias - Rio Pipeline'
          }
        </title>
      </Helmet>
      <Breadcump />
      <NewsPost />
    </Fragment>
  )
}

export default Posts
