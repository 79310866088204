import { Container, SearchBox, ExpositorListing, PaginationContainer } from './styles'
import ExpositorItem from './components/ExpositorItem'
import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

import { client } from '../../Services/wordpress'
import { GET_EXPOSITORS } from '../../queries/pages'
import { formatedExpositors } from '../../utils/formatedData'
import { useLanguage } from '../../hooks/useLanguage'

let expositorsArr
let endOffset
const ITEMS_PER_PAGE = 9

export function ExpositorsList() {
    const [expositors, setExpositors] = useState()
    const [expositorsOffset, setExpositorsOffset] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [currentExpositors, setCurrentExpositors] = useState()
    const [filter, setFilter] = useState('')
    const { language } = useLanguage()

    const handlePageClick = (event) => {
        const newOffset = (event.selected * ITEMS_PER_PAGE) % expositors.length
        setExpositorsOffset(newOffset)
    }

    async function getData() {
        const response = await client.query({
            query: GET_EXPOSITORS
        })

        const { data } = response
        const newData = data.expositores.nodes.map(formatedExpositors)

        expositorsArr = newData
        setExpositors(newData)

        endOffset = expositorsOffset + ITEMS_PER_PAGE
        setCurrentExpositors(expositorsArr.slice(expositorsOffset, endOffset))
        setPageCount(Math.ceil(expositorsArr.length / ITEMS_PER_PAGE))
    }

    function handleSearch() {
        if(filter != '') {
            const filterLowercase = filter.toLowerCase()
            const newExpositors = expositorsArr.filter(expositor => {
                const expositorTitleLowercase = expositor.title.toLowerCase()
                if(expositorTitleLowercase.includes(filterLowercase)) return expositor
            })
            return newExpositors
        }

        const newExpositors = expositorsArr.slice(expositorsOffset, endOffset).filter(expositor => expositor.title.includes(filter))
        return newExpositors
    }

    function handleClearFilter() {
        setFilter('')
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if(expositors) {
            endOffset = expositorsOffset + ITEMS_PER_PAGE
            setCurrentExpositors(expositors.slice(expositorsOffset, endOffset))
            setPageCount(Math.ceil(expositors.length / ITEMS_PER_PAGE))
        }
    }, [expositorsOffset])

    useEffect(() => {
        console.log(filter)
        if(expositors) setCurrentExpositors(handleSearch)
    }, [filter])

    return(
        <Container>
            <SearchBox>
                <button onClick={handleClearFilter}>
                    { language === 'en_US' ? 'Clear filter' : 'Limpar Filtro' }
                </button>
                <input 
                    type="text" 
                    name="search" 
                    id="search" 
                    placeholder={ language === 'en_US' ? 'Search' : 'Pesquisar' }
                    onChange={(event) => setFilter(event.target.value)}
                />
            </SearchBox>
            <ExpositorListing>
                {currentExpositors && currentExpositors.map(expositor => (
                    <ExpositorItem data={expositor} key={expositor.id} />
                ))}
            </ExpositorListing>
            <PaginationContainer>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={ language === 'en_US' ? 'Next' : 'Próximo' }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={ language === 'en_US' ? 'Previous' : 'Anterior' }
                    renderOnZeroPageCount={null}
                />
            </PaginationContainer>
        </Container>
    )
}