import React, { useRef, useEffect } from "react";
import { useField } from "@unform/core";

const InputSelectDinamic = ({
  label,
  name,
  optionType = "uma opção",
  message,
  data,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const text = message ? message : "Carregando opções...";

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue(ref, value) {
        ref.value = value
      },
      clearValue(ref) {
        ref.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <label>
      {label && <p>{label}</p>}
      <select
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
        disabled={data.length > 0 ? "" : "disabled"}>
        <option value=''>
          {data.length > 0 ? optionType : text}
        </option>
        {data &&
          data.map((option) => (
            <option key={option.id} value={option.value || option.name}>
              {option.name}
            </option>
          ))}
      </select>
      {error && <span className='require'>{error}</span>}
    </label>
  );
};

export default InputSelectDinamic;
