import { Container, CTAButton } from "./style"
import { useLanguage } from "../../hooks/useLanguage"

import Banner from '../../assests/v1/featured-expositors.png'

export function FeaturedExpositors() {
    const { language } = useLanguage()

    return(
        <Container>
            <div className="container-content">
                <h2>{language === 'en_US'? 'Rio Pipeline 2023 exhibitors' : 'Expositores Rio Pipeline 2023'}</h2>
                <strong>{language === 'en_US'? 'Exhibition hours: 12 to 20pm.​' : 'Horário de funcionamento da exposição: 12 às 20h.'}</strong>
                <br></br>
                {language === "en_US" ? (
                    <p>Check out all the companies that took part in the exhibition at the last edition of Rio Pipeline.</p>
                ) : (
                    <p>
                        Confira todas as empresas que participaram da exposição na última edição da Rio Pipeline.
                    </p>
                )}
                <CTAButton href={language == 'en_US' ? '/exhibitors' : '/expositores'}>
                {language === 'en_US'? '2023 EXHIBITORS' : 'EXPOSITORES DE 2023'}
                </CTAButton>
            </div>
            <img src={Banner} className="container-banner" />
        </Container>
    )
}

